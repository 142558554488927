import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared.module';

import { MailConfirmComponent } from './mail-confirm.component';

@NgModule({
	imports: [SharedModule, MatButtonModule],
	exports: [MailConfirmComponent],
	declarations: [MailConfirmComponent],
	providers: []
})
export class MailConfirmModule {}
