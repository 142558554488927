export enum ContentPermission {
	HIDDEN = 0,
	CLOSED = 1,
	OPEN_HARD = 2,
	OPEN_SOFT = 3,
	OPEN = 4,
	NEEDS_LOGIN = 5
}

export enum PermissionVisibility {
	RESTRICTIONS = 0,
	HARD_LOGGED = 1,
	SOFT_LOGGED = 2,
	OPEN = 3
}

export enum PermissionVisualizationType {
	// required Signin
	Signin = 0,
	// user not allowed
	Unauthorized = 1,
	// content not found
	NotFound = 2,
	// close dialog
	CloseDialog = 3,
	// User has not activated his account
	UserNotActivated = 4,
	// User has been inactive for too long
	UserSessionExpired = 5,
	// Notify a simple permission problem whitout action on close dialog
	NotifyPermission = 6
}
