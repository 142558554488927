import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CATEGORY_FEATURE_NAME } from '../../shared/shared.state';
import { CategoryEffects } from './category.effects';
import { category } from './category.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(CATEGORY_FEATURE_NAME, category),
		EffectsModule.forFeature([CategoryEffects])
	],
	exports: [],
	declarations: [],
	providers: []
})
export class CategoryModule {}
