/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, filter, map, mapTo, tap } from 'rxjs';
import { closeDialog } from '../../shared/dialog/dn-dialog.actions';
import { LogService } from '../log/log.service';
import {
	navigateHome,
	navigateOffline,
	navigateSignin,
	navigateToContext,
	navigateToPage,
	reloadPage
} from './routes.actions';

@Injectable({ providedIn: 'root' })
export class RoutesEffects {
	constructor(
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		private actions: Actions,
		private logService: LogService
	) {}

	navigateHome$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(navigateHome),
				tap(() => this.logService.info('Effect: navigateHome')),
				tap(() => {
					if (isPlatformBrowser(this.platformId)) {
						this.router.navigate(['home']);
					}
				})
			),
		{ dispatch: false }
	);

	navigateSignin$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(navigateSignin),
				tap(() => this.logService.info('Effect: navigateLogin')),
				tap(() => {
					if (isPlatformBrowser(this.platformId)) {
						this.router.navigate(['signin']);
					}
				})
			),
		{ dispatch: false }
	);

	navigateOffline$ = createEffect(() =>
		this.actions.pipe(
			ofType(navigateOffline),
			// this filter prevents us from navigating to offline when we are already there
			// furthermore, it prevents from overwriting right currentUrl with "/offline", losing the url to get back wwhen
			filter((payload) => payload.currentUrl !== '/offline'),
			tap((action) => this.logService.info('Effect: navigateOffline', action)),
			map(({ currentUrl }) =>
				navigateToPage({ page: '/offline', queryParams: { returnTo: encodeURI(currentUrl) } })
			)
		)
	);

	navigateToPage$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(navigateToPage),
				tap((payload) => this.logService.info('Effect: navigateToPage ', payload.page)),
				tap(({ page, queryParams }) => {
					if (isPlatformBrowser(this.platformId)) {
						this.router.navigate([decodeURI(page)], { queryParams })
						.then(()=> this.logService.info("navigated to ", page))
						.catch((reason: any) => this.logService.info("Couldn't navigate to " + page + ". Reason: ", reason));
					}
				})
			),
		{ dispatch: false }
	);

	/*	reloadCurrentPage$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(reloadPage),
				tap(({ returnTo }) => {
					let url: string;
					let doSomething: boolean = false;
					if (returnTo) {
						switch (returnTo) {
							case 'self':
								url = this.router.url;
								doSomething = true;
								break;
							case null:
								doSomething = false;
								break;
							default:
								url = returnTo;
								doSomething = true;
						}
						if (doSomething) {
							this.router.routeReuseStrategy.shouldReuseRoute = function () {
								return false;
							};
							this.router.onSameUrlNavigation = 'reload';
							this.router.navigate([url]);
						}
					}
				})
			),
		{ dispatch: false }
	);
*/

	navigateToContext$ = createEffect(() =>
		this.actions.pipe(
			ofType(navigateToContext),
			map(() =>
				['articolo', 'dossier', 'file', 'video', 'minisito', 'canale'].includes(
					this.router?.url?.split('/')?.[1]
				)
					? this.router.url.split('/')[1]
					: 'home'
			),
			tap((destination) => this.logService.info('Effect: navigateToContext ', destination)),
			concatMap((context) => [navigateToPage({ page: `/${context}` }), closeDialog()])
		)
	);
}
