import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'dottnet-spinner',
	templateUrl: 'spinner.component.html',
	styleUrls: ['spinner.component.scss']
})
export class SpinnerComponent {
	constructor() {}
}
