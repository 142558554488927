export enum ActionsAfterError {
	LOGIN,
	GUESTLOGIN,
	LOGOUTANDLOGIN,
	RENEW,
	OFFLINE,
	SETERROR,
	ERRORNOTPRESENT
}

export enum ErrorType {
	ErrorNotAssigned = -1000,
	ErrorBackendDown = 0,
	ErrorNotInitialized = 1,
	ErrorUnMapped,
	ErrorUnknown,
	ErrorTokenScaduto,
	ErrorAccessTokenScaduto,
	ErrorRenewTokenScaduto,
	ErrorSoftTokenScaduto,
	ErrorTokenNonValido,
	ErrorLogNonInizializzato,
	ErrorSessioneScaduta,
	ErrorUtenteNonAutorizzato,
	ErrorUtenteInesistente,
	ErrorUsernamePasswordVuoti,
	ErrorHeaderAuthorizationMancante,
	ErrorHeaderAuthorizationScorretto,
	ErrorTipoTokenScorretto,
	ErrorCookieSessioneNonPresente,
	ErrorCookieSessioneVuoto,
	ErrorFormatoCookieSessioneScorretto,
	ErrorSessionExpiresScorretto,
	ErrorTimeLocationScorretta,
	ErrorSitoOffline,
	ErrorHandleNotFound,
	ErrorEmptyParameter,
	ErrorTokenInvalidato,
	ErrorDBTypeNotMapped,
	ErrorRecordsetEmpty,
	ErrorFieldNotFoundInDBSet,
	ErrorDBGeneral,
	ErrorMethodNotAllowed,
	ErrorSessionLoginTypeWrong,
	ErrorTipoLoginScorretto,
	ErrorUtenteNonInDottNet,
	ErrorRenewTokenInvalidato,
	ErrorUpdateDifferentUser,
	ErrorFiscalCodeAlreadyPresent,
	ErrorEmailAlreadyPresent,
	ErrorIpNotAuthorized,
	ErrorApiKeyIncorrect,
	ErrorHostNotAuthorized,
	ErrorOriginNotAuthorized,
	ErrorUtenteCancellato,
	ErrorSpecialtyNotRegisteredToSite,
	ErrorCategoryNotRegisteredToSite,
	ErrorUserNotRegisteredToSite,
	ErrorPrivacyMissing,
	ErrorMarketingMissing,
	ErrorProfilingMissing,
	ErrorThirdMarketingMissing,
	ErrorThirdProfilingMissing,
	ErrorSessionRememberMeWrong,
	ErrorUploadingTempFile,
	ErrorSavingTempFile,
	ErrorMovingTempFile,
	ErrorDeletingTempFile,
	ErrorFfmpeg,
	ErrorPathError,
	ErrorTimeError,
	ErrorNetError,
	ErrorMysqlError,
	ErrorUserNotActive,
	ErrorMailNotConfirmed,
	ErrorCallingIntrospect,
	ErrorOauth2TokenInactive,
	ErrorOauth2TokenWithoutClientID,
	ErrorOauth2TokenWithoutSubject,
	ErrorStructTypeNotManagedInDB,
	ErrorStructDoesntMatchWithJSON,
	ErrorDataNotFound,
	ErrorSearchTooShort,
	ErrorDexieError,
	ErrorPageNotFound,
	ErrorNonso = 10000
}

/* let ErrorDescription = new Map<ErrorType, string>([
	[ServerErrorType.ErrorNotInitialized,'Errore sconosciuto']

]);
*/
export class ErrorMessage extends Error {
	Code: ErrorType = ErrorType.ErrorNotAssigned;
	messageDN: string = '';
	callStack?: string ;
	hint?: string;
	httpStatus?: number;
	httpStatusText?: string;
	url?: string;
	// Name removed 'cause already exists on Error. It could bring to ambiguities
	// name?: string;
	/* name, message stack?*/
}

export const errorActions = new Map<ErrorType, ActionsAfterError>([
	[ErrorType.ErrorBackendDown, ActionsAfterError.OFFLINE],
	[ErrorType.ErrorNotInitialized, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorUnMapped, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorUnknown, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorTokenScaduto, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorAccessTokenScaduto, ActionsAfterError.RENEW],
	[ErrorType.ErrorRenewTokenScaduto, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorSoftTokenScaduto, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorTokenNonValido, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorLogNonInizializzato, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorSessioneScaduta, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorUtenteNonAutorizzato, ActionsAfterError.LOGIN],
	[ErrorType.ErrorUtenteInesistente, ActionsAfterError.LOGIN],
	[ErrorType.ErrorUsernamePasswordVuoti, ActionsAfterError.LOGIN],
	[ErrorType.ErrorHeaderAuthorizationMancante, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorHeaderAuthorizationScorretto, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorTipoTokenScorretto, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorCookieSessioneNonPresente, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorCookieSessioneVuoto, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorFormatoCookieSessioneScorretto, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorSessionExpiresScorretto, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorTimeLocationScorretta, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorSitoOffline, ActionsAfterError.OFFLINE],
	[ErrorType.ErrorHandleNotFound, ActionsAfterError.SETERROR],
	[ErrorType.ErrorEmptyParameter, ActionsAfterError.SETERROR],
	[ErrorType.ErrorTokenInvalidato, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorDBTypeNotMapped, ActionsAfterError.SETERROR],
	[ErrorType.ErrorRecordsetEmpty, ActionsAfterError.SETERROR],
	[ErrorType.ErrorFieldNotFoundInDBSet, ActionsAfterError.SETERROR],
	[ErrorType.ErrorDBGeneral, ActionsAfterError.SETERROR],
	[ErrorType.ErrorMethodNotAllowed, ActionsAfterError.SETERROR],
	[ErrorType.ErrorSessionLoginTypeWrong, ActionsAfterError.LOGOUTANDLOGIN],
	[ErrorType.ErrorTipoLoginScorretto, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorUtenteNonInDottNet, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorRenewTokenInvalidato, ActionsAfterError.GUESTLOGIN],
	[ErrorType.ErrorUpdateDifferentUser, ActionsAfterError.LOGIN],
	[ErrorType.ErrorFiscalCodeAlreadyPresent, ActionsAfterError.SETERROR],
	[ErrorType.ErrorEmailAlreadyPresent, ActionsAfterError.SETERROR],
	[ErrorType.ErrorIpNotAuthorized, ActionsAfterError.SETERROR],
	[ErrorType.ErrorApiKeyIncorrect, ActionsAfterError.SETERROR],
	[ErrorType.ErrorHostNotAuthorized, ActionsAfterError.SETERROR],
	[ErrorType.ErrorOriginNotAuthorized, ActionsAfterError.SETERROR],
	[ErrorType.ErrorUtenteCancellato, ActionsAfterError.LOGIN],
	[ErrorType.ErrorSpecialtyNotRegisteredToSite, ActionsAfterError.SETERROR],
	[ErrorType.ErrorCategoryNotRegisteredToSite, ActionsAfterError.SETERROR],
	[ErrorType.ErrorUserNotRegisteredToSite, ActionsAfterError.LOGIN],
	[ErrorType.ErrorPrivacyMissing, ActionsAfterError.SETERROR],
	[ErrorType.ErrorMarketingMissing, ActionsAfterError.SETERROR],
	[ErrorType.ErrorProfilingMissing, ActionsAfterError.SETERROR],
	[ErrorType.ErrorThirdMarketingMissing, ActionsAfterError.SETERROR],
	[ErrorType.ErrorThirdProfilingMissing, ActionsAfterError.SETERROR],
	[ErrorType.ErrorSessionRememberMeWrong, ActionsAfterError.SETERROR],
	[ErrorType.ErrorUploadingTempFile, ActionsAfterError.SETERROR],
	[ErrorType.ErrorSavingTempFile, ActionsAfterError.SETERROR],
	[ErrorType.ErrorMovingTempFile, ActionsAfterError.SETERROR],
	[ErrorType.ErrorDeletingTempFile, ActionsAfterError.SETERROR],
	[ErrorType.ErrorFfmpeg, ActionsAfterError.SETERROR],
	[ErrorType.ErrorPathError, ActionsAfterError.SETERROR],
	[ErrorType.ErrorTimeError, ActionsAfterError.SETERROR],
	[ErrorType.ErrorNetError, ActionsAfterError.SETERROR],
	[ErrorType.ErrorMysqlError, ActionsAfterError.SETERROR],
	[ErrorType.ErrorUserNotActive, ActionsAfterError.SETERROR],
	[ErrorType.ErrorMailNotConfirmed, ActionsAfterError.SETERROR],
	[ErrorType.ErrorCallingIntrospect, ActionsAfterError.SETERROR],
	[ErrorType.ErrorOauth2TokenInactive, ActionsAfterError.SETERROR],
	[ErrorType.ErrorOauth2TokenWithoutClientID, ActionsAfterError.SETERROR],
	[ErrorType.ErrorOauth2TokenWithoutSubject, ActionsAfterError.SETERROR],
	[ErrorType.ErrorStructTypeNotManagedInDB, ActionsAfterError.SETERROR],
	[ErrorType.ErrorStructDoesntMatchWithJSON, ActionsAfterError.SETERROR],
	[ErrorType.ErrorDataNotFound, ActionsAfterError.SETERROR],
	[ErrorType.ErrorSearchTooShort, ActionsAfterError.SETERROR],
	[ErrorType.ErrorDexieError, ActionsAfterError.SETERROR],
	[ErrorType.ErrorNonso, ActionsAfterError.SETERROR],
	[ErrorType.ErrorPageNotFound, ActionsAfterError.SETERROR],
]);

export const errorMessages = new Map<ErrorType, string>([
	[ErrorType.ErrorRecordsetEmpty, 'dottnet.permessi.notFoundTitle']
]);
