import { filter } from 'rxjs';
import { createSelector } from '@ngrx/store';
import { selectTopicState } from '../../shared/shared.state';
import { TopicListState, TopicState, topicListSelectors, topicSelectors } from './topic.entity';
import { ErrorMessage } from '../error/error-message.model';
import { Topic } from './topic.model';

// Lista
export const selectTopicListError = createSelector(
	selectTopicState,
	(state: TopicState): ErrorMessage => state.topicList.error
);

export const selectTopicList = createSelector(
	selectTopicState,
	(state: TopicState) => state.topicList
);

export const selectAllTopicList = createSelector(selectTopicList, topicListSelectors.selectAll);

// Current
export const selectCurrentTopicState = createSelector(
	selectTopicState,
	(state: TopicState) => state.currentTopic
);

export const selectCurrentTopic = createSelector(
	selectTopicState,
	(state: TopicState) => state.currentTopic?.entities[state.currentTopic.ids[0]]
);

export const selectCurrentTopicError = createSelector(
	selectTopicState,
	(state: TopicState): ErrorMessage => state?.currentTopic?.error
);

export const selectTopicByID = (value: number) =>
	createSelector(selectTopicList, (topic) => topic?.entities[value]);

export const selectTopicByDescription = (value: string) =>
	createSelector(selectAllTopicList, (topic) =>
		topic?.filter((topic) => topic.permalink === value)
	);
