import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { convertToParamMap, ParamMap, Params } from '@angular/router';
import { faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { navigateToContext } from '../../core/router/routes.actions';
import { createSession } from '../../core/session/session.actions';
import { Content } from '../../domain/content/content.model';
import { infoLogo } from '../navigation/navigation.model';
import { closeDialog } from './dn-dialog.actions';

export interface DynamicContainerDialogData {
	content: Content;
	hideImg: boolean;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: ''
})
export abstract class DottnetDialogComponent<T> {
	dialogRef: MatDialogRef<T>;

	infoLogoDn: string = '';

	public get title(): string {
		return this.data?.content?.titolo;
	}

	public get description(): string {
		return this.data?.content?.descrizione;
	}

	public get contentSrc(): string {
		return this.data?.content?.url;
	}

	public get disableClose(): boolean {
		return this.dialogRef.disableClose;
	}

	constructor(
		dialogRef: MatDialogRef<T>,
		@Inject(MAT_DIALOG_DATA) public data: DynamicContainerDialogData,
		public store: Store,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		this.dialogRef = dialogRef;
		this.infoLogoDn = infoLogo;
	}

	getChevronLeftIcon = () => faChevronLeft;

	getCrossIcon = () => faXmark;

	onExit() {
		if (!this.disableClose) this.store.dispatch(closeDialog());
	}

	goToContext() {
		this.store.dispatch(closeDialog());
		this.store.dispatch(navigateToContext());
	}

	// TODO: Why do we need this function here?
	createNewSession(url: string, oldSessionID: number): void {
		let referrer: string = '';
		if (isPlatformBrowser(this.platformId)) {
			referrer = document.referrer;
		}
		const par: Params = <Params>{};
		const params: ParamMap = convertToParamMap(par);
		this.store.dispatch(
			createSession({
				params: params,
				oldSessionID: oldSessionID,
				url: url,
				referrer: referrer
			})
		);
	}
}
