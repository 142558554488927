import { Action, createReducer, on } from '@ngrx/store';
import {
	authGuestLogin,
	authGuestLoginFailure,
	authGuestLoginSuccess,
	authLoadingUser,
	authHardLogin,
	authHardLoginFailure,
	authHardLoginSuccess,
	authLogout,
	authOffline,
	authOnline,
	authRenewToken,
	authRenewTokenFailure,
	authRenewTokenSuccess,
	authSetRenewToken,
	authSoftLogin,
	authSoftLoginFailure,
	authSoftLoginSuccess,
	authUserLoadedHard,
	authUserLoadedSoft,
	authUserLoadedGuest,
	authSetLoginType,
	authNotLogged
} from './auth.actions';
import { AuthLoginState, AuthState, Backend, LoadingState, LoginType, Token } from './auth.models';

export const authInitialState: AuthState = {
	authLoginState: AuthLoginState.NOTLOGGED,
	accessToken: <Token>{},
	renewToken: <Token>{},
	idAnagrafica: 0,
	authProgress: LoadingState.INIT,
	loginType: LoginType.NOLOGIN,
	backendState: Backend.ONLINE
};

const reducer = createReducer(
	authInitialState,
	on(authHardLogin, (state) => ({
		...state,
		authProgress: LoadingState.LOADING,
		loginType: LoginType.HARDLOGIN
	})),
	on(authSoftLogin, (state) => ({
		...state,
		authProgress: LoadingState.LOADING,
		loginType: LoginType.SOFTLOGIN
	})),
	on(authGuestLogin, (state) => ({
		...state,
		authProgress: LoadingState.LOADING,
		loginType: LoginType.GUESTLOGIN
	})),
	on(authHardLoginSuccess, (state, { serverAuthData }) => ({
		...state,
		authLoginState: AuthLoginState.LOGGEDHARD,
		idAnagrafica: serverAuthData.IDAnagrafica,
		accessToken: serverAuthData.AccessToken,
		renewToken: serverAuthData.RenewToken,
		authProgress: LoadingState.LOADED,
		loginType: LoginType.HARDLOGIN
	})),
	on(authSoftLoginSuccess, (state, { serverAuthData }) => ({
		...state,
		authLoginState: AuthLoginState.LOGGEDSOFT,
		idAnagrafica: serverAuthData.IDAnagrafica,
		accessToken: serverAuthData.AccessToken,
		renewToken: serverAuthData.RenewToken,
		authProgress: LoadingState.LOADED,
		loginType: LoginType.SOFTLOGIN
	})),
	on(authGuestLoginSuccess, (state, { serverAuthData }) => ({
		...state,
		authLoginState: AuthLoginState.LOGGEDGUEST,
		idAnagrafica: serverAuthData.IDAnagrafica,
		accessToken: serverAuthData.AccessToken,
		renewToken: serverAuthData.RenewToken,
		authProgress: LoadingState.LOADED,
		loginType: LoginType.GUESTLOGIN
	})),

	on(authOnline, (state) => ({
		...state,
		backendState: Backend.ONLINE
	})),
	on(authOffline, (state, { error }) => ({
		...state,
		backendState: error
	})),

	on(authLogout, (state) => ({
		...state,
		authProgress: LoadingState.LOADING
	})),
	on(authNotLogged, (state) => ({
		...state,
		authLoginState: AuthLoginState.NOTLOGGED,
		accessToken: <Token>{},
		renewToken: <Token>{},
		idAnagrafica: 0,
		loginType: LoginType.NOLOGIN
	})),
	on(authHardLoginFailure, (state, { error }) => ({
		...state,
		// authLoginState: AuthLoginState.NOTLOGGED,
		// accessToken: <Token>{},
		// renewToken: <Token>{},
		// idAnagrafica: 0,
		// loginType: LoginType.NOLOGIN,
		authProgress: error
	})),
	on(authSoftLoginFailure, (state, { error }) => ({
		...state,
		// authLoginState: AuthLoginState.NOTLOGGED,
		// accessToken: <Token>{},
		// renewToken: <Token>{},
		// idAnagrafica: 0,
		// loginType: LoginType.NOLOGIN,
		authProgress: error
	})),
	on(authGuestLoginFailure, (state, { error }) => ({
		...state,
		authLoginState: AuthLoginState.NOTLOGGED,
		accessToken: <Token>{},
		renewToken: <Token>{},
		idAnagrafica: 0,
		loginType: LoginType.NOLOGIN,
		authProgress: error
	})),

	on(authRenewToken, (state) => ({
		...state,
		authLoginState: AuthLoginState.REFRESHING,
		authProgress: LoadingState.LOADING
	})),
	on(authRenewTokenSuccess, (state, { serverAuthData }) => ({
		...state,
		authLoginState:
			serverAuthData.loginType === LoginType.HARDLOGIN
				? AuthLoginState.LOGGEDHARD
				: serverAuthData.loginType === LoginType.SOFTLOGIN
				? AuthLoginState.LOGGEDSOFT
				: AuthLoginState.LOGGEDGUEST,
		idAnagrafica: serverAuthData.IDAnagrafica,
		accessToken: serverAuthData.AccessToken,
		renewToken: serverAuthData.RenewToken,
		authProgress: LoadingState.LOADED,
		loginType: serverAuthData.loginType
	})),
	// TODO Add a fallback page where we tell the user the token is not valid anymore
	on(authRenewTokenFailure, (state, { error }) => ({
		...state,
		authLoginState: AuthLoginState.NOTLOGGED,
		accessToken: <Token>{},
		renewToken: <Token>{},
		idAnagrafica: 0,
		loginType: LoginType.NOLOGIN,
		authProgress: error
	})),
	//  sets the renew token got from localstorage
	on(authSetRenewToken, (state, { token }) => ({ ...state, renewToken: token })),
	on(authSetLoginType, (state, { loginType }) => ({ ...state, loginType })),
	// on(authRenewTokenNotNeeded, (state) => ({ ...state, authProgress: LoadingState.LOADED })),

	// User Interaction
	on(authLoadingUser, (state) => ({ ...state, authProgress: LoadingState.LOADING })),
	on(authUserLoadedHard, (state) => ({
		...state,
		authLoginState: AuthLoginState.LOGGEDHARD,
		authProgress: LoadingState.LOADED,
		loginType: LoginType.HARDLOGIN
	})),
	on(authUserLoadedSoft, (state) => ({
		...state,
		authLoginState: AuthLoginState.LOGGEDSOFT,
		authProgress: LoadingState.LOADED,
		loginType: LoginType.SOFTLOGIN
	})),
	on(authUserLoadedGuest, (state) => ({
		...state,
		authLoginState: AuthLoginState.LOGGEDGUEST,
		authProgress: LoadingState.LOADED,
		loginType: LoginType.GUESTLOGIN
	}))
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
	return reducer(state, action);
}
