import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
	distinctUntilChanged,
	filter,
	map,
	Observable,
	startWith,
	Subscription,
	throttleTime
} from 'rxjs';
import { LogService } from '../../core/log/log.service';
import { Category } from '../../domain/category/category.model';
import { City } from '../../domain/city/city.model';
import { Province } from '../../domain/province/province.model';
import { Specialty } from '../../domain/specialty/specialty.model';
import { GUEST_USER, ListaGruppiEnum, UserDTO } from '../../domain/user/user.model';
import { selectUser } from '../../domain/user/user.selectors';
import { ChangePasswordDialogComponent } from '../dialog/change-password-dialog/change-password-dialog.component';
import { ConsentInfoDialogComponent } from '../dialog/consent-info-dialog/consent-info-dialog.component';
import { openDialog } from '../dialog/dn-dialog.actions';
import { evaluateOneOrZeroFromBoolean } from '../util/util';
import {
	AUTOCOMPLETE_MIN_LETTERS,
	DOCTOR_ID,
	IDS_TO_SHOW_CATEGORY_FIELDS,
	UserForm,
	UserFormConsentsForm,
	UserFormPersonalInfos,
	UserFormType
} from './user-form.model';
import { UserFormService } from './user-form.service';
import { autocompleteObjectValidator } from '../util/validators';
import { EnvironmentService } from '../../../environments/environment.service';

@Component({
	selector: 'dottnet-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['./user-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent implements OnInit, OnDestroy {
	// userObservable may be undefined to show form in signing up mode (blank and with some extra fields)
	@Input() user?: UserDTO;
	@Input() categoryOptions$?: Observable<Category[]> = new Observable<Category[]>();
	@Input() specialtyOptions$?: Observable<Specialty[]> = new Observable();
	@Input() provinceOptions$?: Observable<Province[]> = new Observable();
	@Input() listaGruppi?: ListaGruppiEnum[] = [];
	@Input() formType: UserFormType = UserFormType.SIGNUP;
	@Input() isSubmitting: boolean = false;
	@Input() showPersonal: boolean = false;
	@Input() showProfessional: boolean = false;
	@Input() showConsents: boolean = false;
	@Input() showEmail: boolean = false;
	@Input() showPwd: boolean = false;

	// Observables
	@Input() cityOptions$: Observable<City[]> = new Observable<City[]>();
	@Input() requestForm$: Observable<boolean> = new Observable<boolean>();

	@Output() readonly lookupCities: EventEmitter<string> = new EventEmitter<string>();
	@Output()
	readonly submitForm: EventEmitter<FormGroup<UserForm>> = new EventEmitter<FormGroup<UserForm>>();

	@Output() sendForm: EventEmitter<FormGroup<UserForm>> = new EventEmitter<FormGroup<UserForm>>();

	// Main form group
	componentForm: FormGroup<UserForm>;

	// Filtered options for autocompletes
	categoryFilteredOptions$: Observable<Category[]> = new Observable<Category[]>();
	specialtyFilteredOptions$: Observable<Specialty[]> = new Observable<Specialty[]>();
	provinceFilteredOptions$: Observable<Province[]> = new Observable<Province[]>();

	// Subcriptions
	userSub: Subscription = new Subscription();
	categorySub: Subscription = new Subscription();
	specialtySub: Subscription = new Subscription();
	provinceSub: Subscription = new Subscription();
	requestFormSub: Subscription = new Subscription();

	birthplaceFieldSub: Subscription = new Subscription();
	computeCFSub: Subscription = new Subscription();

	// Instance variables
	categoryOptions: Category[] = [];
	specialtyOptions: Specialty[] = [];
	provinceOptions: Province[] = [];

	privacyPolicyUrl: string;
	imprintUrl: string;

	constructor(
		private formService: UserFormService,
		private logService: LogService,
		private store: Store,
		private environmentService: EnvironmentService,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.privacyPolicyUrl = this.environmentService.privacyPolicyUrl;
		this.imprintUrl = this.environmentService.imprintUrl;
		this.componentForm = this.buildForm(this.listaGruppi);

		// this.initVisualization();
		this.componentForm.updateValueAndValidity();

		this.userSub = this.store
			.select(selectUser)
			.pipe(filter((user) => user && user.idAnagrafica !== GUEST_USER.idAnagrafica))
			.subscribe((user) => this.precompileForm(user));

		this.categorySub = this.categoryOptions$!.subscribe(
			(
				categories // Every category except 'medico non confermato'
			) => (this.categoryOptions = categories.filter((category) => category.idCategoria !== 73))
		);
		this.specialtySub = this.specialtyOptions$!.subscribe(
			(specialties) => (this.specialtyOptions = specialties)
		);

		this.provinceSub = this.provinceOptions$!.subscribe((provinces) => {
			this.provinceOptions = provinces;
		});

		// Update filtered options based on user input
		this.categoryFilteredOptions$ = this.category.valueChanges.pipe(
			startWith<string | Category>(''),
			map((value) => this.filterCategory(value, this.categoryOptions))
		);
		this.specialtyFilteredOptions$ = this.specialty.valueChanges.pipe(
			startWith<string | Specialty>(''),
			map((value) => this.filterSpecialty(value, this.specialtyOptions))
		);
		this.provinceFilteredOptions$ = this.registerProvince.valueChanges.pipe(
			startWith<string | Province>(''),
			map((value) => this.filterProvince(value, this.provinceOptions))
		);

		this.requestFormSub = this.requestForm$.subscribe((send: boolean) => {
			this.componentForm.markAllAsTouched();
			if (send) {
				this.sendForm.emit(this.componentForm);
			}
		});

		// Listen for event to update CF
		this.computeCFSub = this.personalInfos.valueChanges
			.pipe(
				throttleTime(250),
				distinctUntilChanged((before, after) => JSON.stringify(before) === JSON.stringify(after))
			)
			.subscribe(() => this.calcolaCod());
	}

	onBirthplaceChange(fieldValue: string) {
		if (fieldValue.length > AUTOCOMPLETE_MIN_LETTERS) {
			this.logService.info('Emitting lookupCities with value', fieldValue);
			this.lookupCities.emit(fieldValue);
		}
	}

	// Filter function to lookup for matches in category typeahead
	filterCategory(value: any, categoryOptions: Category[]): Category[] {
		const filterValue = value.descrizioneCategoria || value.toLowerCase().trim();
		return categoryOptions.filter((option) =>
			option.descrizioneCategoria.toLowerCase().includes(filterValue)
		);
	}

	// Filter function to lookup for matches in specialty typeahead
	filterSpecialty(value: any, specialtyOptions: Specialty[]): Specialty[] {
		const filterValue = value.descrizioneSpecializzazione || value.toLowerCase().trim();
		return specialtyOptions.filter((option) =>
			option.descrizioneSpecializzazione.toLowerCase().includes(filterValue)
		);
	}

	// Filter function to lookup for matches in province typeahead
	filterProvince(value: any, provinceOptions: Province[]): Province[] {
		const filterValue = value.descrizioneProvincia || value.toLowerCase().trim();
		return provinceOptions.filter((option) =>
			option.descrizioneProvincia.toLowerCase().includes(filterValue)
		);
	}

	ngOnDestroy(): void {
		if (this.userSub) this.userSub.unsubscribe();
		if (this.categorySub) this.categorySub.unsubscribe();
		if (this.specialtySub) this.specialtySub.unsubscribe();
		if (this.birthplaceFieldSub) this.birthplaceFieldSub.unsubscribe();
		if (this.computeCFSub) this.computeCFSub.unsubscribe();
		if (this.requestFormSub) this.requestFormSub.unsubscribe();
		if (this.provinceSub) this.provinceSub.unsubscribe();
	}

	get category() {
		return this.componentForm?.get('professionalInfos')?.get('category');
	}

	get specialty() {
		return this.componentForm.get('professionalInfos').get('specialty');
	}

	get registerNumber() {
		return this.componentForm.get('professionalInfos').get('registerForm').get('registerNumber');
	}

	get registerProvince() {
		return this.componentForm.get('professionalInfos').get('registerForm').get('registerProvince');
	}

	get bornAbroad() {
		return this.componentForm.get('personalInfos').get('bornAbroad');
	}

	get name() {
		return this.componentForm.get('personalInfos').get('name');
	}

	get surname() {
		return this.componentForm.get('personalInfos').get('surname');
	}

	get email() {
		return this.componentForm.get('personalInfos').get('email');
	}

	get gender() {
		return this.componentForm.get('personalInfos').get('gender');
	}

	get birthdate() {
		return this.componentForm.get('personalInfos').get('birthdate');
	}

	get codiceFiscale() {
		return this.componentForm.get('personalInfos').get('codiceFiscale');
	}

	get birthplace() {
		return this.componentForm.get('personalInfos').get('birthplace');
	}

	get marketing() {
		return this.consentsForm.get('marketing');
	}

	get marketingThirdParties() {
		return this.consentsForm.get('marketingThirdParties');
	}

	get profiling() {
		return this.consentsForm.get('profiling');
	}

	get profilingThirdParties() {
		return this.consentsForm.get('profilingThirdParties');
	}

	get personalInfos(): FormGroup<UserFormPersonalInfos> {
		return this.componentForm.get('personalInfos') as FormGroup<UserFormPersonalInfos>;
	}

	get consentsForm(): FormGroup<UserFormConsentsForm> {
		return this.componentForm.get('consentsForm') as FormGroup<UserFormConsentsForm>;
	}

	errorMessage(field: string): string {
		return this.formService.buildErrorMessage<UserForm>(this.componentForm, field);
	}

	private buildForm(listaGruppi: ListaGruppiEnum[]): FormGroup<UserForm> {
		return this.formType === UserFormType.EDIT
			? this.formService.buildEditUserForm(listaGruppi)
			: this.formService.buildRegisterUserForm();
	}

	private precompileForm(user: UserDTO) {
		this.name.setValue(user.nome);
		this.surname.setValue(user.cognome);

		// La mail non è editabile
		this.email.setValue(user.email);
		this.email.disable();

		this.gender.setValue(user.sesso);
		this.birthdate.setValue(user.dataNascita?.toString());

		if (user.comuneNascita) {
			this.bornAbroad.setValue('0');
			this.birthplace.addValidators(Validators.required);
			this.codiceFiscale.disable();
		} else this.bornAbroad.setValue('1');

		this.birthplace.setValue(<City>{
			idComune: user.idComuneNascita,
			descrizioneComune: user.comuneNascita,
			belfiore: user.codiceBelfiore
		});

		this.codiceFiscale.setValue(user.codiceFiscale);

		if (this.isCFComputable()) {
			this.codiceFiscale.setValue(
				this.formService.buildCodiceFiscale(
					this.name.value,
					this.surname.value,
					this.birthplace.value,
					this.birthdate.value,
					this.gender.value
				)
			);
		} else {
			this.codiceFiscale.setValue('');
		}

		if (user.idCategoria) {
			this.category.setValue({
				idCategoria: user.idCategoria,
				descrizioneCategoria: user.categoria
			});

			if (user.idSpecializzazione) {
				this.specialty.setValue({
					idSpecializzazione: user.idSpecializzazione,
					descrizioneSpecializzazione: user.specializzazione
				});
			}

			if (this.isDoctorSelected()) {
				this.specialty.addValidators(Validators.required);
				this.specialty.addValidators(autocompleteObjectValidator());
			} else this.specialty.clearValidators();

			if (this.areShownCategoryFields() && this.showProfessional) {
				this.registerNumber.addValidators(Validators.required);
				this.registerProvince.addValidators(Validators.required);
				this.registerProvince.addValidators(autocompleteObjectValidator());
			}
		}

		this.registerNumber.setValue(user.numeroIscrizioneAlbo?.toString());

		this.provinceOptions$
			.pipe(filter((provinces) => provinces.length !== 0))
			.subscribe((provinces) => {
				provinces
					.filter((province) => province.siglaProvincia === user.provinciaIscrizioneAlbo)
					.forEach((elem) => {
						this.registerProvince.setValue(elem);
					});
			});

		this.marketing.setValue(evaluateOneOrZeroFromBoolean(user.marketing));
		this.marketingThirdParties.setValue(evaluateOneOrZeroFromBoolean(user.marketingTerzi));
		this.profiling.setValue(evaluateOneOrZeroFromBoolean(user.profilazione));
		this.profilingThirdParties.setValue(evaluateOneOrZeroFromBoolean(user.profilazioneTerzi));

		this.componentForm.markAllAsTouched();

		if (this.formType === UserFormType.EDIT && this.componentForm.valid) {
			// in edit if status is valid I have to mark form as dirty in order to activate save button
			this.componentForm.markAsDirty();
		}
	}

	displayCategory(elem: Category) {
		return elem.descrizioneCategoria;
	}

	displaySpecialty(elem: Specialty): string {
		return elem.descrizioneSpecializzazione;
	}

	displayProvince(elem: Province): string {
		return elem.descrizioneProvincia;
	}

	displayCity(elem: City): string {
		return elem.descrizioneComune;
	}

	manageBirthplaceField() {
		if (this.bornAbroad.value === '1') {
			this.birthplace.setValue(<City>{
				idComune: 0,
				descrizioneComune: 'ESTERO'
			});
			this.birthplace.disable();
			this.birthplace.clearValidators();
			this.birthplace.updateValueAndValidity();
			this.codiceFiscale.enable();
			this.codiceFiscale.setValue('');
		} else {
			this.birthplace.setValue(<City>{});
			this.birthplace.enable();
			this.birthplace.addValidators(Validators.required);
			this.codiceFiscale.disable();
			// this.codiceFiscale.setValue('');
		}
	}

	manageCategoryFields() {
		if (this.areShownCategoryFields()) {
			this.registerNumber.addValidators(Validators.required);
			this.registerProvince.addValidators(Validators.required);
			this.registerProvince.addValidators(autocompleteObjectValidator());

			if (this.isDoctorSelected()) {
				this.specialty.addValidators(Validators.required);
				this.specialty.addValidators(autocompleteObjectValidator());
			} else {
				this.specialty.clearValidators();
			}
		} else {
			this.registerNumber.setValue('');
			this.registerProvince.setValue(<Province>{});
			this.registerNumber.clearValidators();
			this.registerProvince.clearValidators();
			this.specialty.clearValidators();
		}
		this.registerNumber.updateValueAndValidity();
		this.registerProvince.updateValueAndValidity();
		this.specialty.updateValueAndValidity();
	}

	areShownCategoryFields() {
		return IDS_TO_SHOW_CATEGORY_FIELDS.includes(this.category.value.idCategoria);
	}

	isDoctorSelected() {
		return DOCTOR_ID === this.category.value.idCategoria;
	}

	emitSubmit() {
		this.submitForm.emit(this.componentForm);
		this.componentForm.markAsPristine();
	}

	calcolaCod() {
		if (this.isCFComputable()) {
			this.codiceFiscale.setValue(
				this.formService.buildCodiceFiscale(
					this.name.value,
					this.surname.value,
					this.birthplace.value,
					this.birthdate.value,
					this.gender.value
				)
			);
		} else if (this.bornAbroad.value === '0') {
			this.codiceFiscale.setValue('');
		}
	}

	isCFComputable() {
		return (
			this.bornAbroad.value === '0' &&
			this.name.value &&
			this.surname.value &&
			this.birthplace?.value?.descrizioneComune &&
			this.birthdate.value &&
			this.gender.value
		);
	}

	// Show form fields according to listaGruppi
	// private initVisualization() {
	// 	this.showEmail = true;
	// 	if (this.listaGruppi?.length === 0) {
	// 		this.showPersonal = true;
	// 		this.showProfessional = true;
	// 		this.showConsents = true;
	// 	} else {
	// 		this.componentForm.updateValueAndValidity();
	// 	}
	// }

	changePassword() {
		this.store.dispatch(
			openDialog({
				content: undefined,
				componentType: ChangePasswordDialogComponent,
				panelClass: 'dn-dialog-small',
				data: { idAnagrafica: this.user.idAnagrafica }
			})
		);
	}

	// Dialog

	openDialog(
		consentType: 'marketing' | 'marketingThirdParties' | 'profiling' | 'profilingThirdParties'
	) {
		this.store.dispatch(
			openDialog({
				content: undefined,
				componentType: ConsentInfoDialogComponent,
				panelClass: 'dn-dialog-medium',
				data: { consentType: consentType }
			})
		);
	}
}
