import { createAction, props } from '@ngrx/store';
import { UserDTO } from '../user/user.model';
import { ErrorMessage } from '../error/error-message.model';
import { Disiscrizione, NewsletterAbbonamentoState } from './settings.model';

export const disiscrizioneNewsletter = createAction(
	'[Disiscrizione] disiscrizioneNewsletter',
	props<{ anc: string; idNewsletter: number }>()
);

export const disiscrizioneNewsletterSuccess = createAction(
	'[Disiscrizione] disiscrizioneNewsletterSuccess',
	props<{ disiscrizione: Disiscrizione }>()
);

export const disiscrizioneNewsletterFailure = createAction(
	'[Disiscrizione] disiscrizioneNewsletterFailure',
	props<{ error: ErrorMessage }>()
);

export const getNewsletterInfo = createAction(
	'[Disiscrizione] getNewsletterInfo',
	props<{ anc: string; idNewsletter: number }>()
);

export const getNewsletterInfoSuccess = createAction(
	'[Disiscrizione] getNewsletterInfoSuccess',
	props<{ newsletterInfo: NewsletterAbbonamentoState }>()
);

export const getNewsletterInfoFailure = createAction(
	'[Disiscrizione] getNewsletterInfoFailure',
	props<{ error: ErrorMessage }>()
);

export const disiscrizioneNewsletterAll = createAction(
	'[Disiscrizione] disiscrizioneNewsletterAll',
	props<{ anc: string }>()
);

export const disiscrizioneNewsletterAllSuccess = createAction(
	'[Disiscrizione] disiscrizioneNewsletterAllSuccess',
	props<{ disiscrizione: Disiscrizione }>()
);

export const disiscrizioneNewsletterAllFailure = createAction(
	'[Disiscrizione] disiscrizioneNewsletterAllFailure',
	props<{ error: ErrorMessage }>()
);

export const disiscrizioneDottnet = createAction(
	'[Disiscrizione] disiscrizioneDottnet',
	props<{ anc: string }>()
);

export const disiscrizioneDottnetSuccess = createAction(
	'[Disiscrizione] disiscrizioneDottnetSuccess',
	props<{ disiscrizione: Disiscrizione }>()
);

export const disiscrizioneDottnetFailure = createAction(
	'[Disiscrizione] disiscrizioneDottnetFailure',
	props<{ error: ErrorMessage }>()
);
