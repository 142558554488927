<!-- Change class of the following div to change app-theme -->
<div class="flex-col flex-justify-space-between light-theme">
	<mat-sidenav-container>
		<mat-sidenav #sidenav mode="over" fixedInViewport="true">
			<dottnet-sidenav
				[sidenav]="sidenav"
				[user]="user$ | async"
				[authLoginState]="authLoginState$ | async"
				[searchText]="searchText"
				[topic]="topic$ | async"
			></dottnet-sidenav>
		</mat-sidenav>
		<mat-sidenav-content class="flex-col flex-justify-space-between desktop-content">
			<dottnet-toolbar
				[sidenav]="sidenav"
				[user]="user$ | async"
				[authLoginState]="authLoginState$ | async"
				[searchText]="searchText"
				[topic]="topic$ | async"
			></dottnet-toolbar>

			<dottnet-cookie-consent></dottnet-cookie-consent>
			<!-- <div *ngxIfConsent="'marketing_google_analytics'"></div>
            <div *ngxIfConsent="'marketing_meta'"></div>
            <div *ngxIfConsent="'functional_google_analytics'"></div> -->
			<div class="flex-row flex-justify-center outlet-container">
				<router-outlet #o="outlet"></router-outlet>
			</div>
			<dottnet-footer [authLoginState$]="authLoginState$"></dottnet-footer>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
<!-- <dottnet-facebook-pixel></dottnet-facebook-pixel> -->
<!-- <dottnet-google-analytics></dottnet-google-analytics> -->
