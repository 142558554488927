import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SPECIALTY_FEATURE_NAME } from '../../shared/shared.state';
import { SpecialtyEffects } from './specialty.effects';
import { specialty } from './specialty.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(SPECIALTY_FEATURE_NAME, specialty),
		EffectsModule.forFeature([SpecialtyEffects])
	],
	exports: [],
	declarations: [],
	providers: []
})
export class SpecialtyModule {}
