import { NgModule } from '@angular/core';
import { CategoryModule } from '../../../domain/category/category.module';
import { CityModule } from '../../../domain/city/city.module';
import { ProvinceModule } from '../../../domain/province/province.module';
import { SpecialtyModule } from '../../../domain/specialty/specialty.module';
import { SharedModule } from '../../shared.module';
import { SpinnerModule } from '../../spinner/spinner.module';
import { UserFormModule } from '../../user-form/user-form.module';
import { ConsentInfoDialogModule } from '../consent-info-dialog/consent-info-dialog.module';
import { DnDialogModule } from '../dn-dialog.module';
import { WallDialogComponent } from './wall-dialog.component';

@NgModule({
	imports: [
		SharedModule,
		DnDialogModule,
		UserFormModule,
		CategoryModule,
		SpecialtyModule,
		CityModule,
		ProvinceModule,
		ConsentInfoDialogModule,
		SpinnerModule
	],
	exports: [],
	declarations: [WallDialogComponent],
	providers: []
})
export class WallDialogModule {}
