import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedOnlyGuardService } from './core/guards/authenticated-only-guard.service';
import { GuestOnlyGuardService } from './core/guards/guest-only-guard.service';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./container/home/home.module').then((m) => m.HomeModule)
	},
	{
		path: 'home',
		loadChildren: () => import('./container/home/home.module').then((m) => m.HomeModule)
	},
	{
		path: 'articolo',
		loadChildren: () => import('./container/article/article.module').then((m) => m.ArticleModule)
	},
	{
		path: 'file',
		loadChildren: () => import('./container/file/file.module').then((m) => m.FileModule)
	},
	{
		path: 'dossier',
		loadChildren: () => import('./container/dossier/dossier.module').then((m) => m.DossierModule)
	},
	{
		path: 'video',
		loadChildren: () => import('./container/video/video.module').then((m) => m.ContainerVideoModule)
	},

	{
		path: 'ecm',
		redirectTo: '/minisito/103469'
	},
	{
		path: 'minisito',
		loadChildren: () =>
			import('./container/dynamic-container/dynamic-container.module').then(
				(m) => m.DynamicContainerModule
			)
	},
	{
		path: 'canale',
		loadChildren: () =>
			import('./container/dynamic-container/dynamic-container.module').then(
				(m) => m.DynamicContainerModule
			)
	},
	{
		path: 'extra',
		loadChildren: () => import('./container/extra/extra.module').then((m) => m.ExtraModule)
	},
	{
		path: 'log',
		loadChildren: () => import('./container/log/log.module').then((m) => m.LogModule)
	},
	{
		path: 'profile',
		loadChildren: () => import('./container/profile/profile.module').then((m) => m.ProfileModule),
		canLoad: [AuthenticatedOnlyGuardService]
	},
	{
		path: 'signup',
		loadChildren: () => import('./container/signup/signup.module').then((m) => m.SignupModule),
		canLoad: [GuestOnlyGuardService]
	},
	{
		path: 'ricerca',
		loadChildren: () =>
			import('./container/search-list/search-list.module').then((m) => m.SearchListModule)
	},
	{
		path: 'offline',
		loadChildren: () => import('./container/offline/offline.module').then((m) => m.OfflineModule)
	},
	/* {
		path: 'corso',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	*/
	{
		path: 'fad',
		loadChildren: () =>
			import('./container/content-list/content-list.module').then((m) => m.ContentListModule)
	},
	// every content detail without appropriate container is opened in iframe
	{
		path: 'fad/:contentId',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'fad/:contentId/:titolo',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'fad/:contentId/:titolo/:debug',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'sondaggio/:contentId',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'landing-page/:contentId',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'landing-page/:contentId/:titolo',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'mail',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'pec',
		loadChildren: () =>
			import('./container/iframe-container/iframe-container.module').then(
				(m) => m.IframeContainerModule
			)
	},
	{
		path: 'chi-siamo',
		loadChildren: () =>
			import('./container/static-container/static-container.module').then(
				(m) => m.StaticContainerModule
			)
	},
	{
		path: 'condizioni-utilizzo',
		loadChildren: () =>
			import('./container/static-container/static-container.module').then(
				(m) => m.StaticContainerModule
			)
	},
	{
		path: 'privacy',
		loadChildren: () =>
			import('./container/static-container/static-container.module').then(
				(m) => m.StaticContainerModule
			)
	},
	{
		path: 'condizioni-utilizzo-extra',
		loadChildren: () =>
			import('./container/static-container/static-container.module').then(
				(m) => m.StaticContainerModule
			)
	},
	{
		path: 'redazione',
		loadChildren: () =>
			import('./container/static-container/static-container.module').then(
				(m) => m.StaticContainerModule
			)
	},
	{
		path: 'settings',
		loadChildren: () => import('./container/settings/settings.module').then((m) => m.SettingsModule)
	},
	{
		path: 'topic',
		loadChildren: () => import('./container/topic/topic.module').then((m) => m.TopicModule)
	},
	{
		path: '**',
		loadChildren: () =>
			import('./container/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule)
	}
];

@NgModule({
	//  useHash supports github.io demo page, remove in your app
	imports: [
		RouterModule.forRoot(routes, {
			/* useHash: true, */
			scrollPositionRestoration: 'enabled',
			preloadingStrategy: NoPreloading,
			// enableTracing: true,
			initialNavigation: 'enabledBlocking'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
