import { loadCityByPrefix, loadCityByPrefixSuccess, loadCityByPrefixFailure } from './city.actions';
import { createReducer, on } from '@ngrx/store';
import { cityAdapter, cityInitialState } from './city.entity';

export const city = createReducer(
	cityInitialState,
	on(loadCityByPrefix, (state) => ({ ...state, error: undefined })),
	on(loadCityByPrefixSuccess, (state, action) => cityAdapter.setAll(action.cityData, state)),
	on(loadCityByPrefixFailure, (state, action) => ({
		...state,
		error: action.error
	}))
);
