export const logo = 'assets/dottnet_logo.png';
export const infoLogo = 'assets/dottnet_info_logo.png';
export const homeLogo = 'assets/home_mobile.png';
export const genericFile = 'assets/generic_document.png';

export const navigation = [
	{
		link: 'content',
		label: 'dottnet.menu.content',
		subLinks: [
			{ link: 'articolo', label: 'dottnet.menu.news' },
			{ link: 'file', label: 'dottnet.menu.document' },
			{ link: 'dossier', label: 'dottnet.menu.dossier' },
			{ link: 'video', label: 'dottnet.menu.video' }
		]
	},
	{ link: 'canale', label: 'dottnet.menu.canale' },
	{ link: 'minisito', label: 'dottnet.menu.minisito' },
	{
		link: 'ecm',
		label: 'dottnet.menu.ecm'
	},
	{
		link: 'extra',
		label: 'dottnet.menu.extra',
		subLinksLogged: [
			{ link: 'extra/my-extra', label: 'dottnet.menu.myextra' },
			// { link: 'my-courses', label: 'dottnet.menu.mycourses' },
			{ link: 'fad', label: 'dottnet.menu.mycourses' },
			{ link: 'extra/article-extra', label: 'dottnet.menu.articleextra' }
		],
		subLinks: [{ link: 'extra/discover-extra', label: 'dottnet.menu.discoverextra' }]
	},
	{
		link: 'iframe',
		label: 'dottnet.menu.toolbox',
		subLinks: [
			{ link: 'fad', label: 'dottnet.menu.fad' },
			{ link: 'pec', label: 'dottnet.menu.pec' },
			{ link: 'mail', label: 'dottnet.menu.mail' }
		]
	}
];

export const navigationSideMenu = [
	{ link: 'settings/edit', label: 'dottnet.menu.settings' },
	{ link: 'settings/log', label: 'dottnet.menu.managelog' }
];
