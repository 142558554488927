import { createFeatureSelector } from '@ngrx/store';
import { CategoryState } from '../domain/category/category.entity';
import { CityState } from '../domain/city/city.entity';
import { ProvinceState } from '../domain/province/province.entity';
import { SpecialtyState } from '../domain/specialty/specialty.entity';
import { DottnetTemplate } from '../domain/template-ct/template-ct.model';
import { TopicState } from '../domain/topic/topic.entity';
// import { UserDTO } from '../domain/user/user.model';
// import { DynamicContainerState } from '../domain/dynamic-container/dynamic-container.reducer';

export const TEMPLATE_FEATURE_NAME: string = 'templateCt';
export const CATEGORY_FEATURE_NAME: string = 'categories';
export const PROVINCE_FEATURE_NAME: string = 'provinces';
export const SPECIALTY_FEATURE_NAME: string = 'specialties';
export const CITY_FEATURE_NAME: string = 'cities';
export const DYNAMIC_FEATURE_NAME: string = 'dynamic';
export const TOPIC_FEATURE_NAME: string = 'topic';

export const selectTemplateCtState = createFeatureSelector<{
	templateCtMap: DottnetTemplate;
}>(TEMPLATE_FEATURE_NAME);

export const selectTopicState = createFeatureSelector<TopicState>(TOPIC_FEATURE_NAME);

export const selectCategoryState = createFeatureSelector<CategoryState>(CATEGORY_FEATURE_NAME);

export const selectProvinceState = createFeatureSelector<ProvinceState>(PROVINCE_FEATURE_NAME);

export const selectSpecialtyState = createFeatureSelector<SpecialtyState>(SPECIALTY_FEATURE_NAME);

export const selectCityState = createFeatureSelector<CityState>(CITY_FEATURE_NAME);
