import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './notification.effects';

@NgModule({
	imports: [EffectsModule.forFeature([NotificationEffects]), MatSnackBarModule],
	exports: [],
	declarations: [],
	providers: []
})
export class NotificationModule {}
