import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PROVINCE_FEATURE_NAME } from '../../shared/shared.state';
import { ProvinceEffects } from './province.effects';
import { province } from './province.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(PROVINCE_FEATURE_NAME, province),
		EffectsModule.forFeature([ProvinceEffects])
	],
	exports: [],
	declarations: [],
	providers: []
})
export class ProvinceModule {}
