import { createSelector } from '@ngrx/store';

import { selectSessionState } from '../core.state';
import { SessionState } from './session.models';

export const selectSessionID = createSelector(
	selectSessionState,
	(state: SessionState) => state.sessionID
);

export const selectSessionError = createSelector(
	selectSessionState,
	(state: SessionState) => state.errorMessage
);
