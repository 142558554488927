import { EnvironmentService } from './../../../../../environments/environment.service';
import { Injectable } from '@angular/core';
import { CookieService } from '../cookie/cookie.service';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CookieConsentService {
	cookieUpdated$ = new Subject<{ name: string; state: boolean }>();

	constructor(
		private cookieService: CookieService,
		private enviromentService: EnvironmentService
	) {}

	getConfig(key: string): any {
		return (this.enviromentService.cookies as any)[key];
	}

	getPrefixedCookieName(name: string): string {
		return this.getConfig('cookiePrefix') + name;
	}

	getCookieFields(): {
		functional: { key: string; selected: boolean }[];
		marketing: { key: string; selected: boolean }[];
	} {
		const functionalCookies = this.getCookiesByCategory('functionalCookies');
		const marketingCookies = this.getCookiesByCategory('marketingCookies');

		return { functional: functionalCookies, marketing: marketingCookies };
	}

	setCookieConsentStatus(status: boolean): void {
		this.cookieService.set(
			this.getPrefixedCookieName('status'),
			status,
			this.getConfig('cookieExpiryDays')
		);
		this.cookieUpdated$.next({
			name: 'status',
			state: status
		});
	}

	setCookieConsentStatusForCookie(name: string, status: boolean): void {
		this.cookieUpdated$.next({
			name,
			state: status
		});

		if (!status) {
			return this.cookieService.delete(this.getPrefixedCookieName(name));
		}

		this.cookieService.set(
			this.getPrefixedCookieName(name),
			status,
			this.getConfig('cookieExpiryDays')
		);
	}

	shouldDisplayCookieConsent(): boolean {
		return !this.cookieService.get(this.getPrefixedCookieName('status'));
	}

	acceptAllCookies(): void {
		const cookies = [
			...this.getConfig('functionalCookies').map((cookie: any) => cookie.key),
			...this.getConfig('marketingCookies').map((cookie: any) => cookie.key)
		];

		cookies.forEach((cookie: string) => {
			this.setCookieConsentStatusForCookie(cookie, true);
		});

		this.setCookieConsentStatus(true);
	}

	denyAllCookies(): void {
		const cookies = [
			...this.getConfig('functionalCookies').map((cookie: any) => cookie.key),
			...this.getConfig('marketingCookies').map((cookie: any) => cookie.key)
		];

		cookies.forEach((cookie: string) => {
			this.setCookieConsentStatusForCookie(cookie, false);
		});

		this.setCookieConsentStatus(true);
	}

	saveSomeCookies(cookies: { functional: any; marketing: any }): void {
		Object.keys(cookies.functional).forEach((cookie: any) => {
			this.setCookieConsentStatusForCookie(cookie, cookies.functional[cookie]);
		});

		Object.keys(cookies.marketing).forEach((cookie: any) => {
			this.setCookieConsentStatusForCookie(cookie, cookies.marketing[cookie]);
		});

		this.setCookieConsentStatus(true);
	}

	getCookiesByCategory(category: string): { key: string; selected: boolean }[] {
		return this.getConfig(category).map((cookie: any) => {
			const cookieKey = cookie.key;
			const cookieState = this.cookieService.get(this.getPrefixedCookieName(cookie.key)) === true;

			return {
				key: cookieKey,
				selected: cookieState
			};
		});
	}

	getCookie(cookieName: string): boolean {
		return this.cookieService.get(this.getPrefixedCookieName(cookieName)) === true;
	}
}
