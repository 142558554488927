import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallService } from '../../core/call-service/call.service';
import { LogService } from '../../core/log/log.service';
import { getSpecialtiesUrl } from '../../core/router/routes-provider';
import { Specialty } from './specialty.model';

@Injectable({ providedIn: 'root' })
export class SpecialtyService {
	constructor(private callService: CallService, private logService: LogService) {}

	loadSpecialty(): Observable<Specialty[]> {
		const urltoCall = getSpecialtiesUrl();
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<Specialty[]>('GET', urltoCall);
		return result;
	}
}
