import { CookieConsentModule } from './core/cookie-consent/cookie-consent.module';
import { isPlatformBrowser } from '@angular/common';
import { APP_INITIALIZER, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import {
	BrowserModule,
	HammerGestureConfig,
	HammerModule,
	HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { interval, merge, Observable, of, take, tap } from 'rxjs';
import { EnvironmentService } from '../environments/environment.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import {
	authGuestLogin,
	authSoftLogin,
	authUserLoadedGuest,
	setLocalStorageAuth
} from './core/auth/auth.actions';
import { AuthLoginState, LoginType, Token } from './core/auth/auth.models';
import { selectAuthLoginState } from './core/auth/auth.selectors';
import { CoreModule } from './core/core.module';
import { LocalStorageService } from './core/local-storage/local-storage.service';
import { LogService } from './core/log/log.service';
import { createSession } from './core/session/session.actions';
import { activateUser, loadUserGuest } from './domain/user/user.actions';
import { SharedModule } from './shared/shared.module';
import { onlyWhenAuthenticated, onlyWhenOnline } from './shared/util/operators';
import { getQueryParametersFromURL, replaceAll } from './shared/util/util';
import { loadTemplateCt } from './domain/template-ct/template-ct.actions';
import { loadTopic } from './domain/topic/topic.actions';
// making hammer config (3)
@Injectable({ providedIn: 'root' })
export class MyHammerConfig extends HammerGestureConfig {
	overrides = <any>{
		pinch: { enable: true }
	};
}

@NgModule({
	imports: [
		//  angular
		// BrowserModule.withServerTransition({ appId: 'dottnetApp' }),
		BrowserAnimationsModule,
		HammerModule,
		// core
		SharedModule,
		CoreModule,

		// cookie consent
		CookieConsentModule.forRoot({
			functionalCookies: [],
			marketingCookies: [],
			essentialCookies: [],
			showCookieDetails: true
		}),

		// app
		AppRoutingModule
	],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	exports: [],
	providers: [
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initDottnet,
			deps: [
				PLATFORM_ID,
				Store,
				LocalStorageService,
				LogService,
				EnvironmentService,
				TranslateService
			],
			multi: true
		}
	]
})
export class AppModule {}

function initDottnet(
	platformId: string,
	store: Store,
	localStorageService: LocalStorageService,
	logService: LogService,
	envService: EnvironmentService,
	translateService: TranslateService
): () => Observable<any> {
	// sometimes we get urls with tile encoded
	// this creates a lot of issues, as the router thinks routes don't match
	// before everything else, let's clean the url
	const currentUrl: URL = new URL(window.location.href);

	let pathName: string = replaceAll(currentUrl.pathname, '(', '%28');
	pathName = replaceAll(pathName, ')', '%29');
	if (pathName !== currentUrl.pathname) {
		currentUrl.pathname = pathName;
		history.pushState({}, '', currentUrl.href);
	}

	store.dispatch(loadTemplateCt());
	store.dispatch(loadTopic());

	if (isPlatformBrowser(platformId)) {
		return () =>
			merge(
				of(window.location.href).pipe(
					tap((path) => {
						translateService.use('it');
						// TODO: remove document
						document.documentElement.lang = 'it';

						logService.init(true, true, false, envService.logLevel);

						let softToken: string = '';
						let confirmationMailToken: string = '';
						let renewFound: boolean = false;
						let softFound: boolean = false;

						const renewToken = <Token>localStorageService.getItem('RENEW');
						const currentLoginType = <LoginType>localStorageService.getItem('LOGINTYPE');

						const url: string = path.replace(envService.clientDomain, '');
						const params: ParamMap = getQueryParametersFromURL(url);

						if (params.has('tkcm') && params.get('tkcm').length > 0) {
							confirmationMailToken = params.get('tkcm');
							store.dispatch(activateUser({ token: confirmationMailToken }));
						} else {
							if (params.has('tksl') && params.get('tksl').length > 0) {
								softToken = params.get('tksl');
								softFound = true;
							}
						}

						if (renewToken !== null && currentLoginType !== null) {
							logService.info('Found renew token in local storage: ', renewToken.token);
							logService.info('Found login type in local storage: ', currentLoginType);

							//  converto tutto in secondi
							const scadenzaRenewToken = renewToken.expires;
							const adesso = Math.floor(Date.now() / 1000);
							//  comparo scadenza con now. Se  ho almeno 5 minuti di autonomia procedo a
							//  refreshare il token, altrimenti lo ignoro
							if (scadenzaRenewToken > adesso && scadenzaRenewToken - adesso > 600) {
								renewFound = true;
							} else {
								localStorageService.removeItem('RENEW');
								localStorageService.removeItem('LOGINTYPE');
							}
						}

						// 	/*
						// 	renewFound|softFound|LoginType| explaination                                      | action
						// 	1   true   |   true  |  HARD   | hard login is stronger than soft login            | use renew token
						// 	2   true   |   true  |  SOFT   | there is an old soft login saved and a new in url | use url token
						// 	3   true   |   true  |  GUEST  | soft login is stronger than guest login           | use url token
						// 	4   true   |   false |  HARD   | found only renew token                            | use renew token
						// 	5   true   |   false |  SOFT   | found only renew token                            | use renew token
						// 	6   true   |   false |  GUEST  | found only renew token                            | use renew token
						// 	7   false  |   true  |  N/A    | found only soft token                             | use url token
						// 	8   false  |   false |  N/A    | found nothing                                     | do guest login
						// */

						//  1
						if (renewFound && softFound && currentLoginType === LoginType.HARDLOGIN) {
							//  I have a renew token for a hard login and a soft login token both valid
							store.dispatch(
								setLocalStorageAuth({ token: renewToken, loginType: currentLoginType })
							);
						}

						//  2 & 3
						if (
							renewFound &&
							softFound &&
							(currentLoginType === LoginType.SOFTLOGIN ||
								currentLoginType === LoginType.GUESTLOGIN)
						) {
							//  I have a renew token for a soft or guest login and a soft login token both valid
							store.dispatch(authSoftLogin({ token: softToken }));
						}

						//  4, 5 & 6
						if (renewFound && !softFound) {
							//  I have a renew token and no soft login token
							store.dispatch(
								setLocalStorageAuth({ token: renewToken, loginType: currentLoginType })
							);
						}

						//  7
						if (!renewFound && softFound) {
							//  I have a soft login token valid
							store.dispatch(authSoftLogin({ token: softToken }));
						}
						if (!renewFound && !softFound) {
							//  user not logged make an anonymous login

							logService.info(
								'Renew token not found in local storage. Doing guest login: ',
								undefined
							);
							store.dispatch(authGuestLogin());
						}
					})
				),
				store.select(selectAuthLoginState).pipe(
					onlyWhenOnline(store),
					onlyWhenAuthenticated(store),
					take(1),
					tap((authLoginState: AuthLoginState) => {
						// TODO: do we need to use window?
						const url: string = window.location.href.replace(envService.clientDomain, '');
						const params: ParamMap = getQueryParametersFromURL(url);

						let oldSessionID = <number>localStorageService.getItem('SESSIONID');
						if (oldSessionID === null) {
							oldSessionID = 0;
						}

						logService.info('Creating session. current login state: ', authLoginState.toString());

						let referrer: string = '';
						if (isPlatformBrowser(platformId)) {
							referrer = document.referrer;
						}
						store.dispatch(
							createSession({
								params: params,
								oldSessionID: oldSessionID,
								url: url,
								referrer: referrer
							})
						);
					})
				)
			);
	} else {
		return () =>
			of(true).pipe(
				tap(() => {
					translateService.use('it');

					logService.info('(AppComponent) Dispatch GuestLogin auth for SSR');
					store.dispatch(authUserLoadedGuest());
					store.dispatch(loadUserGuest({}));
				})
			);
	}
}
