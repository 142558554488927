import { DNOperationState } from '../../shared/util/util';
import { ErrorMessage } from '../error/error-message.model';

export const GUEST_USER: UserDTO = {
	...(<UserDTO>{}),
	idAnagrafica: -1,
	nome: 'Guest',
	cognome: '',
	confermaMail: true
};

export class User {
	email: string; // AKA username
	password: string;
	nome: string;
	cognome: string;
	sesso: string;
	codiceFiscale: string;
	idAnagrafica: number;
	idCategoria: number;
	idSpecializzazione: number;
	idComuneNascita: number;
	dataNascita: Date;
	privacy: boolean;
	marketing: boolean;
	profilazioneTerzi: boolean;
	marketingTerzi: boolean;
	profilazione: boolean;
	numeroIscrizioneAlbo: string;
	provinciaIscrizioneAlbo: string;
	ipAddress: string;
	confermaMail: boolean;
}

export interface UserDTO {
	userName: string;
	password: string;
	nome: string;
	cognome: string;
	sesso: string;
	codiceFiscale: string;
	email: string;
	idAnagrafica: number;
	idCategoria: number;
	categoria: string;
	idSpecializzazione: number;
	specializzazione: string;
	cap: string;
	idProvincia: number;
	idComuneNascita: number;
	comuneNascita: string;
	codiceBelfiore: string;
	provinciaNascita: string;
	dataNascita: Date;
	fotoUtente: string;
	iconaUtente: string;
	privacy: boolean;
	marketing: boolean;
	profilazioneTerzi: boolean;
	marketingTerzi: boolean;
	profilazione: boolean;
	idCarica: number;
	descrizioneCarica: string;
	specializzazioni: string;
	correttezza: boolean;
	noDottNet: boolean;
	noNewsletter: boolean;
	anc: string;
	pecEmail: string;
	pecPassword: string;
	hasMail: boolean;
	hasPec: boolean;
	mostraModaleProfilo: boolean;
	listaGruppi: ListaGruppi;
	premium: boolean;
	listaModuliPremium: string;
	isSellerExtra: boolean;
	dataInizioAbbonamento: Date;
	dataFineAbbonamento: Date;
	numeroIscrizioneAlbo: number;
	provinciaIscrizioneAlbo: string;
	mailUtente: string;
	mailPassword: string;
	confermaMail: boolean;
}

export interface UserState {
	user: UserDTO;
	error: ErrorMessage;
	userOperation: DNOperationState;
}

export enum ListaGruppiEnum {
	Personal,
	Professional,
	Consents
}

export interface ListaGruppi {
	group: string;
	mandatory: boolean;
	message: string;
}

export interface PasswordChange {
	userId: number;
	newPwd: string;
	oldPwd: string;
}
