/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable ngrx/avoid-mapping-selectors */
import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthLoginState } from '../auth/auth.models';
import { selectAuthLoginState } from '../auth/auth.selectors';
import { showSnackbar } from '../notifications/notification.actions';
import { NotificationService } from '../notifications/notification.service';

@Injectable({
	providedIn: 'root'
})
export class GuestOnlyGuardService  {
	constructor(
		private store: Store,
		private router: Router,
		private notificationService: NotificationService
	) {}

	canLoad(
		route: Route,
		segments: UrlSegment[]
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.store.select(selectAuthLoginState).pipe(
			filter(
				(authLoginState) =>
					authLoginState !== AuthLoginState.NOTLOGGED &&
					authLoginState !== AuthLoginState.REFRESHING
			),
			map((authLoginState) => {
				if (authLoginState === AuthLoginState.LOGGEDGUEST) return true;
				else {
					this.store.dispatch(
						showSnackbar({
							payload: this.notificationService.getInfoNotification(
								401,
								"Dear User, you can't access the signup module since you are already logged in"
							)
						})
					);
					return this.router.parseUrl('home');
				}
			})
		);
	}
}
