import { CookieConsentEffects } from './cookie-consent/cookie-consent.effects';
import { FacebookPixelComponent } from './facebook-pixel/facebook-pixel.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { META_REDUCERS, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../environments/environment';
import { EnvironmentService } from '../../environments/environment.service';
import { UserEffects } from '../domain/user/user.effects';
import { WidgetEffects } from '../domain/widget/widget.effects';
import { DialogEffects } from '../shared/dialog/dn-dialog.effects';
import { ImageViewerDialogModule } from '../shared/dialog/image-viewer-dialog/image-viewer-dialog.module';
import { PermissionDialogModule } from '../shared/dialog/permission-dialog/permission-dialog.module';
import { SigninDialogModule } from '../shared/dialog/signin-dialog/signin-dialog.module';
import { WallDialogModule } from '../shared/dialog/wall-dialog/wall-dialog.module';
import { FooterModule } from '../shared/footer/footer.module';
import { SidenavModule } from '../shared/sidenav/sidenav.module';
import { ToolbarModule } from '../shared/toolbar/toolbar.module';
import { ChangePasswordDialogModule } from './../shared/dialog/change-password-dialog/change-password-dialog.module';
import { AuthEffects } from './auth/auth.effects';
import { ClickEffects } from './clicks/clicks.effects';
import { reducers } from './core.state';
import { DottnetErrorHandler } from './error-handler/error-handler';
import { ErrorEffects } from './error-handler/error.effects';
// import { GoogleAnalyticsComponent } from './google-analytics/google-analytics.component';
import { CookieInterceptor } from './interceptors/cookie.interceptor';
import { LogService } from './log/log.service';
import { metaReducerFactory } from './meta-reducers/debug.reducer';
import { MetaService } from './meta/meta.service';
import { NotificationModule } from './notifications/notification.module';
import { CustomSerializer } from './router/custom-serializer';
import { RoutesEffects } from './router/routes.effects';
import { SessionEffects } from './session/session.effects';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { VideoPlayerDialogModule } from '../shared/dialog/video-player-dialog/video-player-dialog.module';
import { ArticleReaderDialogModule } from '../shared/dialog/article-reader-dialog/article-reader-dialog.module';
import { FileReaderDialogModule } from '../shared/dialog/file-reader-dialog/file-reader-dialog.module';

const DIALOG_MODULES = [
	SigninDialogModule,
	WallDialogModule,
	PermissionDialogModule,
	ChangePasswordDialogModule,
	ImageViewerDialogModule,
	VideoPlayerDialogModule
];

export function httpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, `${environment.i18nPrefix}/assets/i18n/`, '.json');
}

export function errorHandlerFactory(injector: Injector) {
	return new DottnetErrorHandler(injector);
}

export function metaServiceFactory(
	title: Title,
	meta: Meta,
	environmentService: EnvironmentService
) {
	return new MetaService(title, meta, environmentService);
}

@NgModule({
	imports: [
		//  NgRx
		StoreModule.forRoot(reducers, {
			// Added to prevent error when opening generic matDialog with ComponentType<ANY> type
			runtimeChecks: {
				strictActionImmutability: false
			}
		}),
		StoreRouterConnectingModule.forRoot(),

		EffectsModule.forRoot([
			AuthEffects,
			UserEffects,
			WidgetEffects,
			//			GoogleAnalyticsEffects,
			ClickEffects,
			SessionEffects,
			DialogEffects,
			RoutesEffects,
			ErrorEffects,
			CookieConsentEffects
		]),

		// Modules required in lazy loaded mods (DnDialogModule & NotificationModule)
		NotificationModule,
		MatDialogModule,
		MatSidenavModule,
		ReactiveFormsModule,
		...DIALOG_MODULES,

		SidenavModule,
		ToolbarModule,
		FooterModule,

		environment.production
			? []
			: StoreDevtoolsModule.instrument({
					name: 'Dottnet'
			  }),

		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],

	providers: [
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
		{ provide: ErrorHandler, deps: [Injector], useFactory: errorHandlerFactory },
		{ provide: RouterStateSerializer, useClass: CustomSerializer },
		{
			provide: META_REDUCERS,
			deps: [LogService],
			useFactory: metaReducerFactory,
			multi: true
		},
		{ provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
		{
			provide: MetaService,
			useFactory: metaServiceFactory,
			deps: [Title, Meta, EnvironmentService]
		}
	],
	declarations: [
		// GoogleAnalyticsComponent,
		// FacebookPixelComponent
	],
	exports: [
		// GoogleAnalyticsComponent,
		// FacebookPixelComponent,
		FooterModule,
		ToolbarModule,
		SidenavModule,
		MatSidenavModule
	]
})
export class CoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		parentModule: CoreModule
	) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import only in AppModule');
		}
	}
}
