import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from '../shared.module';

import { SidenavComponent } from './sidenav.component';

@NgModule({
	imports: [
		SharedModule,
		MatListModule,
		MatIconModule,
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule
	],
	exports: [SidenavComponent],
	declarations: [SidenavComponent],
	providers: []
})
export class SidenavModule {}
