import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RoutesStateUrl } from './routes.state';

@Injectable({ providedIn: 'root' })
export class CustomSerializer implements RouterStateSerializer<RoutesStateUrl> {
	serialize(routerState: RouterStateSnapshot): RoutesStateUrl {
		let route = routerState.root;

		while (route.firstChild) {
			route = route.firstChild;
		}

		const {
			url,
			root: { queryParams }
		} = routerState;
		const { params } = route;

		return { url, params, queryParams };
	}
}
