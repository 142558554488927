/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
	DefaultUrlSerializer,
	PRIMARY_OUTLET,
	Router,
	UrlTree
} from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fromEvent, of } from 'rxjs';
import { catchError,  filter, map, mergeMap, tap } from 'rxjs/operators';
import { EnvironmentService } from '../../../environments/environment.service';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { manageError } from '../../domain/error/error.util';
import { DottnetTemplate, TemplateCt } from '../../domain/template-ct/template-ct.model';
import { selectTemplateAll } from '../../domain/template-ct/template-ct.selectors';
import {
	onlyWhenAuthenticated,
	onlyWhenOnline,
	onlyWhenSession
} from '../../shared/util/operators';
import { selectAuthLoginState } from '../auth/auth.selectors';
import { LogService } from '../log/log.service';
import { selectSessionID } from '../session/session.selectors';
import {
	saveClickLog,
	saveClickLogFailure,
	saveClickLogSuccess,
	saveClick,
	saveClickFailure,
	saveClickSuccess
} from './click.actions';
import { ClickDetail, ClickLogID } from './click.models';
import { ClickService } from './click.service';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { replaceAll } from '../../shared/util/util';

//  click to external sites
@Injectable({ providedIn: 'root' })
export class ClickEffects {
	constructor(
		private logService: LogService,
		private router: Router,
		private store: Store,
		private environmentService: EnvironmentService,
		private clickService: ClickService,
		private actions$: Actions,
		// Document represents the rendering context. When switching to browser, it coincides with the DOM
		@Inject(DOCUMENT) private ssrDocument: Document
	) { }

	saveClick$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClick),
			tap((action) => this.logService.info('Effect: saveClick ', action)),
			filter((payload) => !!payload.clickTo),
			onlyWhenSession(this.store),
			onlyWhenAuthenticated(this.store),
			concatLatestFrom(() => [
				this.store.select(selectSessionID),
				this.store.select(selectTemplateAll())
			]),
			tap((event) => this.logService.info('saveClick received event', event)),

			map(([payload, sessionId, templateArray]) =>
				this.getClickParametersFromUrl(
					payload.clickTo,
					sessionId,
					templateArray,
					payload?.action ? payload.action : '',
					this.environmentService.clientDomain,
					this.environmentService.contentPath,
					this.logService
				)
			),
			mergeMap((clickDetail) => {
				this.logService.debug('Effect: saveClick calling CreatePageView ');
				return this.clickService.CreatePageView(clickDetail).pipe(
					tap((response) => this.logService.infoDebug('Effect: saveClickSuccess ', response)),
					map(() => saveClickSuccess()),
					catchError((error: ErrorMessage) => of(saveClickFailure({ error })))
				);
			})
		)
	);

	//  save failure
	saveClickFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClickFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: saveClickFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	saveExternalClicks = createEffect(() =>
		//  get all click events
		fromEvent(this.ssrDocument, 'click').pipe(
			tap((event) => this.logService.info('Effect: saveExternalClicks', event)),
			//  we just want PointerEvents, that is a complete touch or a complete click
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			filter((event) => event instanceof PointerEvent),
			//  at the moment, we only are getting click events on A and IMG tags, considering links are only there
			//  this could prove wrong, it has to be verified
			filter((event: PointerEvent) => {
				const htmlEvent: HTMLElement = <HTMLElement>event.target;
				if (
					htmlEvent.tagName &&
					(htmlEvent.tagName.toUpperCase() === 'A' || htmlEvent?.parentElement?.tagName === 'A')
				) {
					return true;
				} else {
					this.logService.trace('saveExternalclicks: not managed event', htmlEvent.parentElement);
					this.logService.trace(
						'saveExternalclicks: not managed event parent tagname',
						htmlEvent.parentElement?.tagName ? htmlEvent.parentElement.tagName : 'tagName missing in parentelement'
					);
					return false;
				}
			}),
			map((pointerEvent) => {
				//  to page is in href, if tag is A, or in parent.href, if it's an  image
				let tagError: boolean = false;
				let htmlEvent: HTMLElement = <HTMLElement>pointerEvent.target;
				let clickTo: string = '';
				// if we are here, either tagname is A or parent tagname is A.
				// We need the A element
				if (htmlEvent.tagName.toUpperCase() !== 'A') {
					htmlEvent = <HTMLElement>htmlEvent.parentElement;
				}

				// get href if possible
				this.logService.debug('saveExternalclicks: found tag A');
				const aTarget: HTMLAnchorElement = <HTMLAnchorElement>htmlEvent;
				// if href is empty it could be a dialog opening. In that case we have href in attribute contenthref
				if (aTarget.href.length > 0) {
					clickTo = aTarget.href;
				} else if (aTarget.getAttribute('contenthref')?.length > 0) {
					clickTo = aTarget.getAttribute('contenthref');
				}

				if (clickTo.length === 0) {
					tagError = true;
				}

				if (!tagError) {
					// internal lnks are managed elsewhere
					tagError = !this.isExternalUrl(
						clickTo,
						this.environmentService.clientDomain,
						this.environmentService.contentPath
					);
				}

				if (!tagError) {
					return clickTo;
				}
			}),
			map((clickTo) => saveClick({ clickTo: clickTo }))
		)
	);

	savePageViews = createEffect(() =>
		/*		this.router.events.pipe(
					filter((event) => event instanceof NavigationEnd),
			*/
		this.actions$.pipe(
			ofType(ROUTER_NAVIGATED),
			tap((action: RouterNavigationAction) => this.logService.info('Effect: click savePageviews ', action)),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			onlyWhenSession(this.store),
			map((action: RouterNavigationAction) => action.payload.routerState.url),
			map((url: string) => saveClick({ clickTo: url }))

			/*		
					map((event) => event as NavigationEnd),
					tap((event) => this.logService.info('Effect: click savePageviews: ', event)),
					filter((event) => !!event.urlAfterRedirects),
					map((event) => event.urlAfterRedirects),
					map((url: string) => saveClick({ clickTo: url }))
				)
			*/
		));

	saveClickLog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClickLog),
			tap((action) => this.logService.info('Effect: saveClickLog ', action)),
			map((payload) => payload.clickLog),
			filter((clickLog) => !!clickLog),
			concatLatestFrom(() => [this.store.select(selectSessionID)]),
			tap((event) => this.logService.info('saveClickLog received event', event)),

			mergeMap(([clickLog, sessionID]) => {
				this.logService.debug('Effect: saveClickLog calling CreateClickLog ');
				clickLog.sessionID = sessionID;
				return this.clickService.CreateClickLog(clickLog).pipe(
					tap((response: ClickLogID) =>
						this.logService.infoDebug('Effect: saveClickLogSuccess ', response)
					),
					map(() => saveClickLogSuccess()),
					catchError((error: ErrorMessage) => of(saveClickLogFailure({ error })))
				);
			})
		)
	);

	//  save failure
	saveClickLogFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveClickLogFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: saveClickLogFailure ', action)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	getClickParametersFromUrl(
		url: string,
		sessionID: number,
		templateAttributes: DottnetTemplate,
		action: string,
		rootDomain: string,
		contentPath: string,
		logService: LogService
	): ClickDetail {
		const clickDetail: ClickDetail = <ClickDetail>{};
		let contextTemplate: string = '';
		let contentTemplate: string = '';
		let analyzeUrl: boolean = true;
		let isContentFile: boolean = false;

		clickDetail.contentID = 0;
		clickDetail.contentTypeID = 0;
		clickDetail.contextID = 0;
		clickDetail.contextTypeID = 0;
		clickDetail.templateID = 0;

		clickDetail.sessionID = sessionID;

		if (!url || typeof url !== 'string' || url.length === 0) {
			logService.error('Url not correct. Click detail not generated', url);
			return clickDetail;
		}

		// understand id it's an internal or external link
		isContentFile = false;
		if (url.startsWith('http')) {
			// remove host if necessary
			if (url.startsWith(rootDomain)) {
				// internal link. Remove host and go on
				url = url.slice(rootDomain.length);
				analyzeUrl = true;
			} else if (url.startsWith(contentPath)) {
				// internal link. Remove host and go on
				// content link to a content file
				url = url.slice(contentPath.length);
				analyzeUrl = true;
				isContentFile = true;
			} else {
				//  external link manage it separately
				//  permalink is everything before querystring
				const segments: string[] = url.split('?', 2);
				clickDetail.page = 'External page';
				clickDetail.permaLink = segments[0];
				clickDetail.templateID = 14;
				if (segments.length > 1) {
					clickDetail.queryString = segments[1];
				}
				analyzeUrl = false;
			}
		}
		if (analyzeUrl) {
			// extract url segments and fragments
			// TODO: manage / url
			const urlObj: UrlTree = new DefaultUrlSerializer().parse(url);
			let segments: string[] = <string[]>[];
			if (urlObj.root?.children[PRIMARY_OUTLET]?.segments) {
				segments = urlObj.root.children[PRIMARY_OUTLET].segments.map(
					(urlSegment) => urlSegment.path
				);
			} else {
				logService.error('Cannot find segments in url:', url);
			}

			let queryString: string = '';
			if (urlObj.queryParams && Object.keys(urlObj.queryParams).length > 0) {
				queryString = Object.keys(urlObj.queryParams)
					.map((key) => key + '=' + urlObj.queryParams[key])
					.join('&');
			}
			if (urlObj.fragment != null && urlObj.fragment.length > 0) {
				queryString += '#' + urlObj.fragment;
			}

			clickDetail.queryString = queryString;

			// sometimes the first segment is 0 lenght because the link starts with //
			if (segments?.length > 0) {
				if (segments[segments.length - 1].length === 0) {
					segments.splice(segments.length - 1, 1);
				}
			}
			if (segments?.length > 0) {
				segments = segments.map((segment) => replaceAll(segment,"'", "''"));

				if (action.length > 0) {
					clickDetail.page = action;
				} else {
					clickDetail.page = segments[0];
				}

				contextTemplate = '';
				contentTemplate = '';
				if (isContentFile) {
					// it's a link to a contenf, like file, video
					// so it's /content/{{template}}/{{contentID}}-filename.ext
					clickDetail.permaLink = '/' + segments.join('/');
					if (segments.length > 1) {
						if (templateAttributes[segments[1]] !== undefined) {
							contentTemplate = segments[1];
						}
					}
					clickDetail.contentID = parseInt(segments[2].substring(0, segments[2].indexOf('-')), 10);
				} else {
					switch (segments.length) {
						case 1:
							//  single page, like /home, /privacy
							//  get template templateAttributes
							if (templateAttributes[segments[0]] !== undefined) {
								contextTemplate = segments[0];
							}
							clickDetail.permaLink = '/' + segments.join('/');
							break;
						case 2:
							//  container/id (no title)
							clickDetail.permaLink = '/' + segments.join('/');
							if (templateAttributes[segments[0]] !== undefined) {
								contentTemplate = segments[0];
							}
							clickDetail.contentID = parseInt(segments[1], 10);
							break;
						case 3:
							// container,id, title
							clickDetail.permaLink = '/' + segments.join('/');
							if (templateAttributes[segments[0]] !== undefined) {
								contentTemplate = segments[0];
							}
							clickDetail.contentID = parseInt(segments[1], 10);
							break;
						case 4:
							//  container,id,content,content_id
							clickDetail.permaLink = '/' + segments[2] + '/' + segments[3];
							if (templateAttributes[segments[0]] !== undefined) {
								contextTemplate = segments[0];
							}
							contentTemplate = segments[2];
							clickDetail.contextID = parseInt(segments[1], 10);
							clickDetail.contentID = parseInt(segments[3], 10);
							break;
						case 5:
						case 6:
						case 7:
							//  container,id,content,content_id, title
							clickDetail.permaLink = '/' + segments[2] + '/' + segments[3] + '/' + segments[4];
							if (templateAttributes[segments[0]] !== undefined) {
								contextTemplate = segments[0];
							}
							if (templateAttributes[segments[2]] !== undefined) {
								contentTemplate = segments[2];
							}
							clickDetail.contextID = parseInt(segments[1], 10);
							clickDetail.contentID = parseInt(segments[3], 10);
							break;
						default:
							this.logService.error(
								'getclickParametersFromUrl: Url object segments over maximum ',
								urlObj.root.children[PRIMARY_OUTLET].segments
							);
					}
					if (contextTemplate.length > 0) {
						// TODO: check existence
						if (templateAttributes[contentTemplate] !== undefined) {
							const tmpTemplate: TemplateCt = templateAttributes[contextTemplate];
							if (clickDetail.contextID === 0) {
								clickDetail.contextID = tmpTemplate.contextID;
							}
							clickDetail.contextTypeID = tmpTemplate.contextTypeID;
							clickDetail.templateID = tmpTemplate.templateID;
						}
					}
				}
				// this is shared between content file links and normal links
				if (contentTemplate.length > 0) {
					// TODO: check existence
					if (templateAttributes[contentTemplate] !== undefined) {
						const tmpTemplate: TemplateCt = templateAttributes[contentTemplate];
						clickDetail.contentTypeID = tmpTemplate.contentTypeID;
						clickDetail.templateID = tmpTemplate.templateID;
					}
				}
			}
		}

		logService.info('Click detail generated: ', clickDetail);
		return clickDetail;
	}
	isExternalUrl(url: string, rootDomain: string, contentPath: string): boolean {
		if (!url || typeof url !== 'string' || url.length === 0) {
			return false;
		}

		// understand id it's an internal or external link
		if (!url.startsWith('http')) {
			return false;
		}
		// remove host if necessary
		if (url.startsWith(rootDomain)) {
			// internal link. Remove host and go on
			return false;
		}
		if (url.startsWith(contentPath)) {
			// internal link. Remove host and go on
			// content link to a content file
			return false;
		}
		return true;
	}
}
