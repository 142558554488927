import { createSelector } from '@ngrx/store';
import { selectCityState } from '../../shared/shared.state';
import { ErrorMessage } from '../error/error-message.model';
import { citySelectors, CityState } from './city.entity';

export const selectCityByPrefix = createSelector(selectCityState, citySelectors.selectAll);

export const selectCityByPrefixError = createSelector(
	selectCityState,
	(state: CityState): ErrorMessage => state.error
);
