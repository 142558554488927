import { DefaultUrlSerializer, ParamMap, UrlTree } from '@angular/router';
import {
	isTemplateRaccolta,
	LegacyTemplateIdEnum
} from '../../container/dynamic-container/legacy-dynamic-container/legacy-widgets/legacy-widget.model';
import { AuthLoginState } from '../../core/auth/auth.models';
import { Content } from '../../domain/content/content.model';
import { PageElement } from '../../domain/dynamic-container/dynamic-container.model';
import { TemplateCt } from '../../domain/template-ct/template-ct.model';
import {
	ContentPermission,
	PermissionVisibility,
	PermissionVisualizationType
} from '../dialog/permission-dialog/permission.model';

// Utility to link actions one to another
export enum DNOperationState {
	INIT = 'init',
	LOADING = 'loading',
	OK = 'ok',
	ERROR = 'error'
}

const GUID_LENGTH = 12;

// String ops
export const getPosition = (string, subString, index) =>
	string.split(subString, index).join(subString).length;

// Html X string ops

// Get an html attribute value
export const getFirstHtmlAttributeOccurrence = (html: string, attribute: string): string =>
	replaceAll(
		replaceAll(
			html
				// Start: first occurrence of attribute + its lenght;
				// End: last " character indicating that the value is over
				.substring(html.indexOf(attribute) + attribute.length, getPosition(html, '"', 2)),
			// Remove useless chars
			'=',
			''
		),
		'"',
		''
	);

// Remove first occurrence attribute from html string
export const removeFirstHtmlAttributeOccurrence = (html: string, attribute: string): string => {
	// Split over >, we need the first one
	const splittedHtml = html.split('>');
	// Remove the intended attribute
	const firstTagWithoutAttribute = splittedHtml?.[0]
		?.split(' ')
		.filter((elem) => !elem.startsWith(attribute))
		.join(' ');
	// Remove old tag
	const result = splittedHtml.slice(1);
	// Insert new tag without attribute at the beginning of result
	result.unshift(firstTagWithoutAttribute);
	// Build html string as it was
	return result.join('>');
};

function escapeRegExp(str: string): string {
	return str.replace(/[.*+?^${}\(\)|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll(str: string, find: string, replace: string) {
	return str ? str.replace(new RegExp(escapeRegExp(find), 'g'), replace) : str;
}

// Generate guid from a random number (non unique assurance) multiplied with current date
// Take only the first GUID_LENGTH chars starting from right side
export const generateGuid = (): string =>
	Math.floor(Math.random() * Date.now())
		.toString()
		.slice(-GUID_LENGTH);

// Boolean evaluations
export const evaluateBooleanFromString = (boolean: string) => (boolean === '1' ? true : false);

export const evaluateOneOrZeroFromBoolean = (value: boolean): string => (value ? '1' : '0');

// Date Util

export const getAge = (dateString: string): number => {
	const today = new Date();
	const birthDate = new Date(dateString);
	let age = today.getFullYear() - birthDate.getFullYear();
	const m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

export const getDateInUTC = (date: Date): Date =>
	new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

export function getQueryParametersFromURL(url: string): ParamMap {
	const urlObj: UrlTree = new DefaultUrlSerializer().parse(url);
	return urlObj.queryParamMap;
}

// Filter functions
export const areLegacyWidgetsLoaded = (pageElems: PageElement[]): boolean => {
	for (const pageElem of pageElems) {
		// If the widget is a generic html
		if (
			[LegacyTemplateIdEnum.HTML_GENERIC, LegacyTemplateIdEnum.VIDEO].includes(pageElem.idTemplate)
		) {
			// Check if the additional data is absent
			if (!pageElem.hasOwnProperty('widgetDetail')) {
				return false;
				// Check if the elem refers to a carousel
			} else if (pageElem.widgetDetail.corpo.includes('dn-carousel')) {
				// If it is a carousel, it has to have a contentList
				if (!pageElem.hasOwnProperty('contentList')) {
					return false;
				}
			}
		}
		// If the widget is a collection
		if (isTemplateRaccolta(pageElem.idTemplate)) {
			// Check if additional data has been loaded
			if (!pageElem.hasOwnProperty('contentList')) {
				return false;
			}
		}
	}

	return true;
};
export const areNewWidgetsLoaded = (pageElems: PageElement[]): boolean => {
	for (const pageElem of pageElems) {
		// If the widget is a collection
		if (isTemplateRaccolta(pageElem.idTemplate)) {
			// Check if additional data has been loaded
			if (!pageElem.hasOwnProperty('contentList')) {
				return false;
			}
		}
	}

	return true;
};

// Dottnet related

export const trackByContentId = (content: Content) => content.idContenuto;

export const getPermission = (
	templateVisibility: PermissionVisibility,
	authLoginState: string,
	isAbilitato: ContentPermission,
	isUserActivated: boolean
): PermissionVisualizationType => {
	//  setto il tipo di permesso, seguendo le indicazioni dello schema permessi.pptx in doc
	//  per leggibilità mi suddivido l'if
	let permissionType: PermissionVisualizationType = PermissionVisualizationType.CloseDialog;

	if (
		(templateVisibility === PermissionVisibility.SOFT_LOGGED &&
			authLoginState === AuthLoginState.LOGGEDGUEST) ||
		(templateVisibility === PermissionVisibility.HARD_LOGGED &&
			(authLoginState === AuthLoginState.LOGGEDGUEST ||
				authLoginState === AuthLoginState.LOGGEDSOFT))
	) {
		permissionType = PermissionVisualizationType.Signin;
	}

	// If the user has not activated his account yet
	if (!isUserActivated && authLoginState !== AuthLoginState.LOGGEDGUEST)
		permissionType = PermissionVisualizationType.UserNotActivated;

	if (
		templateVisibility === PermissionVisibility.RESTRICTIONS &&
		((isAbilitato === ContentPermission.OPEN_SOFT &&
			authLoginState === AuthLoginState.LOGGEDGUEST) ||
			(isAbilitato === ContentPermission.OPEN_HARD &&
				(authLoginState === AuthLoginState.LOGGEDGUEST ||
					authLoginState === AuthLoginState.LOGGEDSOFT)))
	) {
		permissionType = PermissionVisualizationType.Signin;
	}

	if (
		templateVisibility === PermissionVisibility.RESTRICTIONS &&
		isAbilitato === ContentPermission.CLOSED &&
		(authLoginState === AuthLoginState.LOGGEDHARD || authLoginState === AuthLoginState.LOGGEDSOFT)
	) {
		permissionType = PermissionVisualizationType.Unauthorized;
	}

	if (
		templateVisibility === PermissionVisibility.RESTRICTIONS &&
		isAbilitato === ContentPermission.CLOSED &&
		authLoginState === AuthLoginState.LOGGEDGUEST
	) {
		permissionType = PermissionVisualizationType.Signin;
	}

	if (
		templateVisibility === PermissionVisibility.RESTRICTIONS &&
		isAbilitato === ContentPermission.HIDDEN &&
		(authLoginState === AuthLoginState.LOGGEDHARD || authLoginState === AuthLoginState.LOGGEDSOFT)
	) {
		permissionType = PermissionVisualizationType.NotFound;
	}

	if (
		// (templateVisibility === PermissionVisibility.RESTRICTIONS &&
		// 	authLoginState === AuthLoginState.LOGGEDGUEST) ||
		authLoginState === AuthLoginState.NOTLOGGED ||
		authLoginState === AuthLoginState.REFRESHING
	) {
		permissionType = PermissionVisualizationType.Signin;
	}

	return permissionType;
};

export const isContentPage = (url: string, contentTemplates: TemplateCt[]): boolean =>
	contentTemplates.map((template) => template.templateDescription).includes(url?.split('/')?.[1]);

/**
   * FUNZIONE PER MANTENERE LA CONSISTENZA DELL'URL
	Example result: 'www.dottnet.it/articolo/2313421/il-titolo-articolo'

	This function ensures that the url is consistent no matter where you come from.
	routeUrl is the part after the base path '/articolo/2313421/il-titolo-articolo',
	so the components are '' + 'articolo' + '2313421' + 'il-titolo-articolo'
  */
export const customUrl = (
	url: string,
	title: string,
	template?: string,
	contentId?: number
): string => {
	if (title && title.length > 0) {
		title = title
			.replace(/ /g, '-')
			.replace(/\'/g, '-')
			.replace(new RegExp(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/, 'gi'), '');
	}
	const routeUrlComp = url.split('/');
	let routeUrl: string;
	if (routeUrlComp.length > 2 && !!contentId) {
		routeUrl =
			routeUrlComp[0] +
			'/' +
			routeUrlComp[1] +
			'/' +
			routeUrlComp[2] +
			'/' +
			template +
			'/' +
			contentId +
			'/' +
			title;
	} else if (routeUrlComp.length > 2) {
		routeUrl = routeUrlComp[0] + '/' + routeUrlComp[1] + '/' + routeUrlComp[2] + '/' + title;
	} else {
		routeUrl = url + '/' + title;
	}
	return replaceAll(routeUrl, '%', '-');
};
