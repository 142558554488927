import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { EnvironmentService } from '../../../environments/environment.service';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { Content } from '../../domain/content/content.model';
import { ContentService } from '../../domain/content/content.service';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { selectUserId, selectUserIsActivated } from '../../domain/user/user.selectors';
import { getPermission } from '../util/util';
import {
	checkPermissionDialog,
	closeDialog,
	errorDialog,
	evaluatePermissionDialog,
	loadDialog,
	openDialog
} from './dn-dialog.actions';
import { PermissionDialogComponent } from './permission-dialog/permission-dialog.component';
import { PermissionVisualizationType } from './permission-dialog/permission.model';
import { showSnackbar } from '../../core/notifications/notification.actions';
import { NotificationService } from '../../core/notifications/notification.service';

@Injectable({ providedIn: 'root' })
export class DialogEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private dialogRef: MatDialog,
		private logService: LogService,
		private notificationService: NotificationService,
		private contentService: ContentService,
		private envService: EnvironmentService
	) {}

	loadDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadDialog),
			tap((payload) => this.logService.info('Effect: loadDialog ' + JSON.stringify(payload))),
			concatLatestFrom(() => this.store.select(selectUserId)),
			switchMap(([payload, userId]) =>
				this.contentService.loadContentById(userId, payload.contentId, payload.template).pipe(
					tap((response) =>
						this.logService.infoDebug('Effect: loadDialog data received --> ', response)
					),
					map((response: Content) =>
						openDialog({
							content: { ...response, url: this.envService.contentPath + response.url },
							data: payload.dataDialog,
							componentType: payload.componentType
						})
					),
					catchError((error: ErrorMessage) => of(errorDialog({ error })))
				)
			)
		)
	);

	errorDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(errorDialog),
			tap((action) => this.logService.info('Effect: errorDialog', action)),
			map((action) => action.error),
			map((error) =>
				showSnackbar({
					payload: this.notificationService.getNotificationFromError(error)
				})
			)
		)
	);

	openDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openDialog),
				tap((payload) => this.logService.info('Effect: openDialog')),
				tap(({ content, componentType, disableClose, data, panelClass }) => {
					// this.dialogRef.openDialogs.length === 0
					// 	?
					this.dialogRef.open(componentType, {
						closeOnNavigation: true,
						disableClose: disableClose,
						panelClass: panelClass ? panelClass : 'dn-dialog-large',
						data: {
							content: content,
							...data
						}
					});
					// : undefined
				})
			),
		{ dispatch: false }
	);

	closeDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(closeDialog),
				tap((action) => this.logService.info('Effect: DialogClosed', action)),
				tap(() => this.dialogRef.openDialogs.pop()?.close())
			),
		{ dispatch: false }
	);

	checkPermissionDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(checkPermissionDialog),
			tap((action) => {
				this.logService.info(
					'Effect: checkPermissionDialog templateVisibility ',
					action.templateVisibility
				);
			}),
			concatLatestFrom(({ selectorAbilitato }) =>
				selectorAbilitato ? selectorAbilitato : of(undefined)
			),
			concatLatestFrom(() => this.store.select(selectUserIsActivated)),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			filter(
				([
					[[{ templateVisibility, selectorAbilitato }, isAbilitato], isUserActivated],
					authLoginState
				]) =>
					getPermission(templateVisibility, authLoginState, isAbilitato, isUserActivated) !==
					PermissionVisualizationType.CloseDialog
			),
			map(
				([
					[[{ templateVisibility, selectorAbilitato }, isAbilitato], isUserActivated],
					authLoginState
				]) =>
					openDialog({
						content: undefined,
						componentType: PermissionDialogComponent,
						disableClose: true,
						panelClass: 'dn-dialog-small',
						data: {
							templateVisibility: templateVisibility,
							selectorAbilitato: selectorAbilitato
						}
					})
			)
		)
	);

	evaluatePermissionDialog$ = createEffect(() =>
		this.actions$.pipe(
			ofType(evaluatePermissionDialog),
			tap((action) => {
				this.logService.info('Effect: evaluatePermissionDialog ', action.type);
			}),
			concatLatestFrom(({ abilitatoSelector }) => [
				abilitatoSelector ? abilitatoSelector : of(undefined),
				this.store.select(selectUserIsActivated),
				this.store.select(selectAuthLoginState)
			]),
			map(
				([
					{ templateVisibility, abilitatoSelector, nextAction },
					isAbilitato,
					isUserActivated,
					authLoginState
				]) => {
					if (
						getPermission(templateVisibility, authLoginState, isAbilitato, isUserActivated) !==
						PermissionVisualizationType.CloseDialog
					) {
						return openDialog({
							content: undefined,
							componentType: PermissionDialogComponent,
							disableClose: true,
							panelClass: 'dn-dialog-small',
							data: {
								templateVisibility: templateVisibility,
								selectorAbilitato: abilitatoSelector,
								nextAction
							}
						});
					} else {
						return nextAction;
					}
				}
			)
		)
	);
}
