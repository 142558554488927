import { createSelector } from '@ngrx/store';
import { getAuthProgressError, getBackendStateError } from '../../domain/error/error.util';
import { selectAuthState } from '../core.state';
import { AuthLoginState, AuthState, Backend, LoadingState, LoginType } from './auth.models';

export const selectAuthError = createSelector(selectAuthState, (state: AuthState) =>
	getAuthProgressError(state.authProgress)
);

export const selectBackendStateError = createSelector(selectAuthState, (state: AuthState) =>
	getBackendStateError(state.backendState)
);

export const selectLoadingState = createSelector(selectAuthState, (state: AuthState) =>
	getAuthProgressError(state.authProgress) != null ? LoadingState.ERROR : state.authProgress
);

export const selectIsAuthenticated = createSelector(
	selectAuthState,
	(state: AuthState) =>
		state.authLoginState === AuthLoginState.LOGGEDHARD ||
		state.authLoginState === AuthLoginState.LOGGEDSOFT ||
		state.authLoginState === AuthLoginState.LOGGEDGUEST
);

export const selectAuthLoginState = createSelector(
	selectAuthState,
	(state: AuthState) => state.authLoginState
);

export const selectGetAccessToken = createSelector(
	selectAuthState,
	(state: AuthState) => state.accessToken
);

export const selectGetRenewToken = createSelector(
	selectAuthState,
	(state: AuthState) => state.renewToken
);

export const selectGetLoginType = createSelector(
	selectAuthState,
	(state: AuthState) => state.loginType
);

export const selectGetBackendState = createSelector(selectAuthState, (state: AuthState) =>
	getBackendStateError(state.backendState) != null
		? Backend.OFFLINE
		: (state.backendState as Backend)
);

export const selectGetLoginTypeString = createSelector(
	selectAuthState,
	(state: AuthState) => LoginType[state.loginType]
);
