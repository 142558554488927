import { Action, createReducer, on } from '@ngrx/store';
import { NewsletterAbbonamentoState } from './settings.model';
import { getNewsletterInfoSuccess } from './settings.actions';

export const newsletterAbbonamentoInitialState: NewsletterAbbonamentoState = {
	codiceErrore: undefined,
	descrizioneNewsletter: undefined,
	idNewsletter: undefined,
	nonInteressato: undefined
};

export const reducer = createReducer(
	newsletterAbbonamentoInitialState,
	on(getNewsletterInfoSuccess, (state, { newsletterInfo }) => ({
		...state,
		codiceErrore: newsletterInfo.codiceErrore,
		descrizioneNewsletter: newsletterInfo.descrizioneNewsletter,
		idNewsletter: newsletterInfo.idNewsletter,
		nonInteressato: newsletterInfo.nonInteressato
	}))
);

export function settingsReducer(
	state: NewsletterAbbonamentoState | undefined,
	action: Action
): NewsletterAbbonamentoState {
	return reducer(state, action);
}
