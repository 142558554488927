import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { City } from './city.model';

export const loadCityByPrefix = createAction(
	'[City] LoadCityByPrefix',
	props<{ prefix: string }>()
);

export const loadCityByPrefixSuccess = createAction(
	'[City] loadCityByPrefixSuccess',
	props<{ cityData: City[] }>()
);
export const loadCityByPrefixFailure = createAction(
	'[City] loadCityByPrefixFailure',
	props<{ error: ErrorMessage }>()
);
