import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMapTo, tap } from 'rxjs';
import { LogService } from '../../core/log/log.service';
import { loadCategory } from '../../domain/category/category.actions';
import { loadProvince } from '../../domain/province/province.actions';
import { loadSpecialty } from '../../domain/specialty/specialty.actions';
import { loadDropDownData } from './user-form.actions';

@Injectable({ providedIn: 'root' })
export class UserFormEffects {
	constructor(private actions$: Actions, private logService: LogService) {}

	loadDropDownData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadDropDownData),
			tap((action) => this.logService.info('Effect: loadDropDownData ', action)),
			mergeMapTo([loadCategory(), loadSpecialty(), loadProvince()])
		)
	);
}
