import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { DnDialogModule } from '../dn-dialog.module';
import { ConsentInfoDialogComponent } from './consent-info-dialog.component';

@NgModule({
	imports: [SharedModule, DnDialogModule],
	exports: [ConsentInfoDialogComponent],
	declarations: [ConsentInfoDialogComponent],
	providers: []
})
export class ConsentInfoDialogModule {}
