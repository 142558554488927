// custom operators
// https://indepth.dev/posts/1421/rxjs-custom-operators

import { concatLatestFrom } from '@ngrx/effects';
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { debounce, filter, map, MonoTypeOperatorFunction, Observable, tap, throttle } from 'rxjs';
import { Backend, LoadingState } from '../../core/auth/auth.models';
import {
	selectGetBackendState,
	selectIsAuthenticated,
	selectLoadingState
} from '../../core/auth/auth.selectors';
import { selectSessionID } from '../../core/session/session.selectors';
import { selectUserId } from '../../domain/user/user.selectors';

// Operator factory: a function that accepts  a parameter (store) and returns an operator
// MonoTypeOperatorFunction<T> == (source$: Observable<T>) => Observable<T>

// getOneAndWaitforLoaded gets a single value emitted from the input observable, then starts discarding all others until LoadingState becomes LOADED || ERROR
export function getOneAndWaitforLoaded<T>(store: Store): MonoTypeOperatorFunction<T> {
	return (source$) =>
		source$.pipe(
			// throttle gets first value emitted, then shrinks all sequent values until the
			// observer taken as parameter emits
			// our little dear semaphore
			throttle(() =>
				store
					.select(selectLoadingState)
					.pipe(
						filter(
							(authProgress) =>
								authProgress === LoadingState.LOADED || authProgress === LoadingState.ERROR
						)
					)
			)
		);
}

// getOneAndWaitforOnline gets a single value emitted from the input observable, then starts discarding all others until BackendState becomes ONLINE
export function getOneAndWaitforOnline<T>(store: Store): MonoTypeOperatorFunction<T> {
	return (source$) =>
		source$.pipe(
			// throttle gets first value emitted, then shrinks all sequent values until the
			// observer taken as parameter emits
			// our little dear semaphore
			throttle(() =>
				store
					.select(selectGetBackendState)
					.pipe(filter((backendState) => backendState === Backend.ONLINE))
			)
		);
}
// Operator factory: a function that accepts  a parameter (store) and returns an operator
export function onlyWhenOnline<T>(store: Store): MonoTypeOperatorFunction<T> {
	return (source$) =>
		source$.pipe(
			concatLatestFrom(() => store.select(selectGetBackendState)),
			// eslint-disable-next-line no-console
			// tap(([_, backendState]) => console.log('Entering OnlyWhenOnline', backendState)),
			filter(([_, backendState]) => backendState === Backend.ONLINE),
			map(([input]) => input)
		);
}

// onlyWhenAuthenticated holds objects emitted from input observable until there is a valid user
export function onlyWhenAuthenticated<T>(store: Store): MonoTypeOperatorFunction<T> {
	return (source$) =>
		source$.pipe(
			// eslint-disable-next-line no-console
			// tap(() => console.log('Entering onlyWhenAuthenticated')),
			debounce(() => store.select(selectUserId).pipe(filter((userId: Number) => !!userId))),
			map((source) => source)
		);
}

// onlyWhenSession holds objects emitted from input observable until there is a valid session
export function onlyWhenSession<T>(store: Store): MonoTypeOperatorFunction<T> {
	return (source$) =>
		source$.pipe(
			debounce(() =>
				store.select(selectSessionID).pipe(filter((sessionId: number) => sessionId > 0))
			),
			map((source) => source)
		);
}

export function filterIfAlreadyLoaded<T, P, S extends object>(
	store: Store,
	selector: MemoizedSelector<S, P, DefaultProjectorFn<P>>
): (source$: Observable<T>) => Observable<T> {
	return (source$) =>
		source$.pipe(
			concatLatestFrom(() =>
				store
					.select(selector)
					.pipe(filter((data: any) => (data?.length ? data.length === 0 : !!data)))
			),
			map(([source]) => source)
		);
}
