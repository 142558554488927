import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID
} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { TranslateService } from '@ngx-translate/core';
import { combineLatestWith, filter, Observable, Subscription, withLatestFrom } from 'rxjs';
import { authHardLogin } from '../../../core/auth/auth.actions';
import { selectAuthError, selectAuthLoginState } from '../../../core/auth/auth.selectors';
import { navigateToContext } from '../../../core/router/routes.actions';
import { ErrorMessage } from '../../../domain/error/error-message.model';
import { resendActivationMail } from '../../../domain/user/user.actions';
import { UserDTO } from '../../../domain/user/user.model';
import { selectUser, selectUserIsActivated } from '../../../domain/user/user.selectors';
import { getPermission } from '../../util/util';
import { closeDialog } from '../dn-dialog.actions';
import { DottnetDialogComponent } from '../dn-dialog.component';
import { PayloadSignin } from '../signin-dialog/signin-form/signin-form.component';
import {
	ContentPermission,
	PermissionVisibility,
	PermissionVisualizationType
} from './permission.model';
import { LogService } from '../../../core/log/log.service';
import { AuthLoginState } from '../../../core/auth/auth.models';
import { selectSessionID } from '../../../core/session/session.selectors';
import { Router } from '@angular/router';

export interface PermissionDialogData {
	templateVisibility: number;
	permissionTitle?: string;
	selectorAbilitato?: Observable<ContentPermission>;
	permissionType?: PermissionVisualizationType;
	nextAction?: TypedAction<any>;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'dottnet-permission-dialog',
	templateUrl: './permission-dialog.component.html',
	styleUrls: ['./permission-dialog.component.scss']
})
export class PermissionDialogComponent
	extends DottnetDialogComponent<PermissionDialogComponent>
	implements OnInit, OnDestroy
{
	// Observable
	errorMessage$: Observable<ErrorMessage>;
	user$: Observable<UserDTO> = new Observable();

	authState: string;
	permissionType: PermissionVisualizationType = PermissionVisualizationType.CloseDialog;
	// Subs
	authStateSub: Subscription;
	abilitatoStateSub: Subscription;

	//  Reference to access the enum in the template
	readonly PermissionVisualizationType = PermissionVisualizationType;
	readonly ContentPermission = ContentPermission;
	readonly Visibility = PermissionVisibility;

	constructor(
		private ts: TranslateService,
		dialogRef: MatDialogRef<PermissionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private permissionData: PermissionDialogData,
		public store: Store,
		private logService: LogService,
		private changeDetector: ChangeDetectorRef,
		@Inject(PLATFORM_ID) platformId: Object,
		private router: Router
	) {
		super(dialogRef, undefined, store, platformId);
	}

	ngOnInit() {
		if (this.permissionData.permissionType) {
			this.permissionType = this.permissionData.permissionType;
		}

		this.user$ = this.store.select(selectUser).pipe(filter((user) => !!user.idAnagrafica));

		this.errorMessage$ = this.store
			.select(selectAuthError)
			.pipe(filter((error: ErrorMessage) => !!error));

		// to ri-evaluate permissions we need to subscribe to isAbilitato changes
		// only when selectorAilitato emit we combine value with  latest value of authLoginState
		// we need to combine with confermaMail value returned by selectUserIsActivated selector to filter the chain
		if (this.permissionData.selectorAbilitato) {
			this.authStateSub = this.permissionData.selectorAbilitato
				.pipe(
					// withLatestFrom
					// Creates a combined stream from source and other provided streams.
					// When source emits a value — resulting Observable will emit a combined value of source emission and all latest values on provided streams.
					withLatestFrom(
						this.store.select(selectAuthLoginState),
						this.store
							.select(selectUserIsActivated)
							.pipe(filter((isActivated) => isActivated !== undefined))
					)
				)
				.subscribe(([isAbilitato, authLoginState, isActivated]) => {
					if (!this.permissionData.permissionType) {
						const currentPermissionType = getPermission(
							this.permissionData.templateVisibility,
							authLoginState,
							isAbilitato,
							isActivated
						);

						if (currentPermissionType === PermissionVisualizationType.CloseDialog) {
							this.store.dispatch(closeDialog());

							if (this.permissionData.nextAction) {
								this.store.dispatch(this.permissionData.nextAction);
							}
						} else {
							this.permissionType = !isActivated
								? PermissionVisualizationType.UserNotActivated
								: currentPermissionType;
						}

						this.changeDetector.detectChanges();
					} else {
						if (this.permissionData.permissionType === PermissionVisualizationType.CloseDialog) {
							this.store.dispatch(closeDialog());

							if (this.permissionData.nextAction) {
								this.store.dispatch(this.permissionData.nextAction);
							}
						}
					}
				});
		}
		this.authStateSub = this.store
			.select(selectAuthLoginState)
			.pipe(
				filter((authLoginState) => authLoginState === AuthLoginState.LOGGEDHARD),
				combineLatestWith(this.store.select(selectSessionID))
			)
			.subscribe(([_, oldSessionID]) => {
				this.logService.info('Creating session after login. Old session: ', oldSessionID);
				const url = this.router.url;
				if (this.permissionType === PermissionVisualizationType.Signin) {
					this.store.dispatch(closeDialog());
				}
				this.createNewSession(url, oldSessionID);
			});
	}

	ngOnDestroy(): void {
		if (this.abilitatoStateSub) this.abilitatoStateSub.unsubscribe();
		if (this.authStateSub) this.authStateSub.unsubscribe();
	}

	getPermissionTitle(): string {
		let permissionTitle;

		if (this.permissionData.permissionTitle) {
			return this.permissionData.permissionTitle;
		}

		switch (this.permissionType) {
			case PermissionVisualizationType.Signin:
				permissionTitle = `${this.ts.instant('dottnet.permessi.login')}`;
				break;
			case PermissionVisualizationType.Unauthorized:
				permissionTitle = `${this.ts.instant('dottnet.permessi.unauthorizedTitle')}`;
				break;
			case PermissionVisualizationType.NotFound:
				permissionTitle = `${this.ts.instant('dottnet.permessi.notFoundTitle')}`;
				break;
			case PermissionVisualizationType.UserNotActivated:
				permissionTitle = `${this.ts.instant('dottnet.permessi.activateMail')}`;
				break;
			case PermissionVisualizationType.UserSessionExpired:
				permissionTitle = `${this.ts.instant('dottnet.permessi.sessionExpiredTitle')}`;
				break;
			case PermissionVisualizationType.CloseDialog:
				break;
			default:
				permissionTitle = `${this.ts.instant('dottnet.permessi.attenzione')}`;
				break;
		}

		return permissionTitle;
	}

	getPermissionBody(): string {
		let permissionBody = '';

		switch (this.permissionType) {
			case PermissionVisualizationType.Unauthorized:
				permissionBody = `${this.ts.instant('dottnet.permessi.unauthorized')}`;
				break;
			case PermissionVisualizationType.NotFound:
				permissionBody = `${this.ts.instant('dottnet.permessi.notFound')}`;
				break;
			case PermissionVisualizationType.UserNotActivated:
				permissionBody = '';
				break;
			case undefined:
				permissionBody = '';
				break;
			case PermissionVisualizationType.CloseDialog:
				break;
			default:
				permissionBody = `${this.ts.instant('dottnet.shared.assistenza')}`;
				break;
		}

		return permissionBody;
	}

	onPost(payloadSignin: PayloadSignin) {
		this.store.dispatch(authHardLogin(payloadSignin));
	}

	goToContext() {
		super.onExit();
		// if permissionType is not of simple notification type, go to context to disable access to page
		if (this.permissionType !== PermissionVisualizationType.NotifyPermission) {
			this.store.dispatch(navigateToContext());
		}
	}

	sendMail() {
		this.store.dispatch(resendActivationMail());
	}
}
