import { loadCategory, loadCategorySuccess, loadCategoryFailure } from './category.actions';
import { createReducer, on } from '@ngrx/store';
import { categoryAdapter, categoryInitialState } from './category.entity';

export const category = createReducer(
	categoryInitialState,
	on(loadCategory, (state) => ({ ...state, error: undefined })),
	on(loadCategorySuccess, (state, action) => categoryAdapter.setAll(action.datiCategory, state)),
	on(loadCategoryFailure, (state, action) => ({ ...state, error: action.error }))
);
