import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from '../shared.module';

import { ToolbarComponent } from './toolbar.component';

@NgModule({
	imports: [
		SharedModule,
		MatMenuModule,
		MatIconModule,
		MatToolbarModule,
		MatInputModule,
		MatButtonModule,
		MatFormFieldModule,
		FormsModule
	],
	exports: [ToolbarComponent],
	declarations: [ToolbarComponent],
	providers: []
})
export class ToolbarModule {}
