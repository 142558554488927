import { TemplateTypeId } from '../../domain/template-ct/template-ct.model';

interface CustomRoute {
	name: string;
	urlValue: string;
}

// Accepted param types to use in url
type AcceptedParamsType = string | number | boolean;

const routesMap = new Map<string, CustomRoute[]>([
	[
		'article',
		[
			{
				name: 'related',
				urlValue:
					'/article/related/category/$/tpl/$/contentid/$/contextid/$/userid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'mostread',
				urlValue: '/article/mostread/userid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'latestnews',
				urlValue: '/article/latestnews/userid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'interesting',
				urlValue: '/article/interesting/tpl/$/listacategorie/$/userid/$/fromwhere/$/howmany/$'
			}
		]
	],
	[
		'auth',
		[
			{
				name: 'login',
				urlValue: '/login'
			},
			{
				name: 'softtoken',
				urlValue: '/softtoken'
			},
			{
				name: 'softlogin',
				urlValue: '/softlogin'
			},
			{
				name: 'logout',
				urlValue: '/logout'
			},
			{
				name: 'renew',
				urlValue: '/renew'
			}
		]
	],
	[
		'session',
		[
			{
				name: 'create',
				urlValue: '/createsession'
			},
			{
				name: 'createpageview',
				urlValue: '/createpageview'
			},
			{
				name: 'createclicklog',
				urlValue: '/createclicklog'
			}
		]
	],
	[
		'user',
		[
			{
				name: 'register',
				urlValue: '/user'
			},
			{
				name: 'activate',
				urlValue: '/user/confermamail/token/$'
			},
			{
				name: 'resendMail',
				urlValue: '/user/anothermail/email/$/userid/$'
			},
			{
				name: 'update',
				urlValue: '/user'
			},
			{
				name: 'getbyid',
				urlValue: '/user/$'
			},
			{
				name: 'checkMail',
				urlValue: '/user/checkemail/email/$/userid/$'
			},
			{
				name: 'checkCF',
				urlValue: '/user/checkcf/cf/$/userid/$'
			},
			{
				name: 'retrievepassword',
				urlValue: '/user/retrievepassword/email/$'
			},
			{
				name: 'changepassword',
				urlValue: '/user/changepassword'
			}
		]
	],
	[
		'container',
		[
			{
				name: 'getbyid',
				urlValue: '/container/contentid/$/userid/$'
			},
			{
				name: 'getsottoscrizione',
				urlValue: '/container/sottoscrizione/userid/$/contentid/$/contenttypeid/$/clientsessionid/$'
			}
		]
	],
	[
		'content',
		[
			{
				name: 'getbyid',
				urlValue: '/content/contentid/$/userid/$/template/$'
			},
			{
				name: 'children',
				urlValue: '/content/collection/$/detail/$/userid/$'
			},
			{
				name: 'list',
				urlValue: '/content/template/$/userid/$/fromwhere/$/howmany/$/premium/$'
			},
			{
				name: 'search',
				urlValue: '/content/search/userid/$/search/$/fromwhere/$/howmany/$'
			},
			{
				name: 'topic',
				urlValue: '/content/topic/categoryid/$/userid/$/fromwhere/$/howmany/$/premium/$'
			},
			{
				name: 'related',
				urlValue:
					'/content/related/category/$/tpl/$/contentid/$/contextid/$/userid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'mostread',
				urlValue: '/content/mostread/userid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'latestnews',
				urlValue: '/content/latestnews/userid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'correlatibycategorie',
				urlValue:
					'/content/correlatibycategorie/tpl/$/listacategorie/$/userid/$/contentid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'latestvideos',
				urlValue: '/content/latestvideos/userid/$/fromwhere/$/howmany/$'
			},
			{
				name: 'commentsbyid',
				urlValue: '/content/commentid/$/userid/$/template/$'
			},
			{
				name: 'insertcomment',
				urlValue:
					'/content/comment/parentid/$/parenttpl/$/title/$/text/$/authorid/$/attachmentid/$/attachmenttypeid/$/tpl/$/clientsessionid/$'
			},
			{
				name: 'temporarymedia',
				urlValue: '/content/comment/media/userid/$'
			},
			{
				name: 'insertmediaandcomment',
				urlValue:
					'/content/comment/insertmedia/parentid/$/parenttpl/$/title/$/text/$/commenttpl/$/clientsessionid/$/userid/$/mediatplid/$/mediacontenttypeid/$/filename/$/mediatpl/$'
			},
			{
				name: 'highlight',
				urlValue: '/content/highlight/idrubrica/$/isdetail/$/userid/$/tpl/$/categoriaid/$'
			},
			{
				name: 'highlight-list',
				urlValue: '/content/highlight/idrubrica/$/isdetail/$/userid/$/tpl/$/fromwhere/$/howmany/$'
			}
		]
	],
	[
		'drclick',
		[
			{
				name: 'clientbyid',
				urlValue: '/container/clientid/$'
			},
			{
				name: 'checkconsents',
				urlValue: '/container/consents/clientid/$/userid/$'
			},
			{
				name: 'saveconsents',
				urlValue: '/container/consents'
			}
		]
	],
	[
		'log',
		[
			{
				name: 'save',
				urlValue: '/log/save/guid/$'
			}
		]
	],
	[
		'wall',
		[
			{
				name: 'campigruppimuroprofilo',
				urlValue: '/wall/userid/$/groups/$'
			}
		]
	],
	[
		'topic',
		[
			{
				name: 'getall',
				urlValue: '/topic'
			}
		]
	],
	[
		'category',
		[
			{
				name: 'getall',
				urlValue: '/categories'
			}
		]
	],
	[
		'province',
		[
			{
				name: 'getall',
				urlValue: '/provinces'
			}
		]
	],
	[
		'specialty',
		[
			{
				name: 'getall',
				urlValue: '/specialties'
			}
		]
	],
	[
		'city',
		[
			{
				name: 'byprefix',
				urlValue: '/cities/prefix/$'
			}
		]
	],
	[
		'templatect',
		[
			{
				name: 'getall',
				urlValue: '/templatect'
			}
		]
	],
	[
		'machform',
		[
			{
				name: 'getbyid',
				urlValue: '/machform/formid/$'
			}
		]
	],
	[
		'premium',
		[
			{
				name: 'modules',
				urlValue: '/extra/packages/userid/$/fc/$'
			},
			{
				name: 'checktoken',
				urlValue: '/extra/checktoken/token/$/userid/$'
			},
			{
				name: 'setAbbonamento',
				urlValue: '/extra/setabbonamento'
			},
			{
				name: 'mypremium',
				urlValue: '/extra/mypremium/userid/$'
			},
			{
				name: 'discountType',
				urlValue: '/extra/discounttype'
			},
			{
				name: 'createCoupon',
				urlValue: '/extra/createcoupon/idtiposconto/$/userid/$'
			},
			{
				name: 'updateModule',
				urlValue:
					'/extra/updatemodule/moduleid/$/automaticrenewal/$/idtiposconto/$/subscriptionid/$'
			}
		]
	],
	[
		'zoom',
		[
			{
				name: 'getsignature',
				urlValue: '/zoom/getsignature/meetingnumber/$/role/$'
			}
		]
	],
	[
		'settings',
		[
			{
				name: 'disiscrizionenewsletter',
				urlValue: '/settings/disiscrizionenewsletter/anc/$/idnewsletter/$'
			},
			{
				name: 'newsletterInfo',
				urlValue: '/settings/newsletterinfo/anc/$/idnewsletter/$'
			},
			{
				name: 'disiscrizionenewsletterall',
				urlValue: '/settings/disiscrizionenewsletterall/anc/$'
			},
			{
				name: 'disiscrizionedottnet',
				urlValue: '/settings/disiscrizionedottnet/anc/$'
			}
		]
	]
]);

export function getEndpointFor(
	domain: string,
	methodName: string,
	...params: AcceptedParamsType[]
): string {
	const url = getRouteFor(domain, methodName);

	// Se la prima parola è Error
	if (url.split(' ')[0] === 'Error') return url;

	return addParametersToUrl(url, ...params);
}

function getRouteFor(key: string, methodName: string): string {
	if (routesMap.has(key)) {
		const availableRoutes = routesMap.get(key);
		const foundRoute = availableRoutes.filter((route) => route.name === methodName);
		if (foundRoute.length === 1) {
			const { urlValue } = foundRoute[0];
			return urlValue;
		}
	}
	return 'Error retrieving method: ' + key;
}

function addParametersToUrl(url: string, ...params: AcceptedParamsType[]): string {
	// Se i parametri passati non hanno lo stesso numero dei parametri richiesti dal metodo
	const requestedNumberOfParams = countInstances(url, '$');
	if (requestedNumberOfParams !== params.length)
		return `Error with the number of parameters. Got: ${params.length} | Requested: ${requestedNumberOfParams}`;
	// Se i parametri passati sono zero come quelli richiesti
	if (requestedNumberOfParams === 0) return url;

	const splittedUrl = url.split('/');
	const result: string[] = [];
	for (let i = 0, j = 0; i < splittedUrl.length && j < params.length; i++) {
		if (splittedUrl[i] !== undefined && splittedUrl[i] === '$') {
			result[i] = params[j]?.toString();
			j++;
		} else result[i] = splittedUrl[i];
	}

	return result.join('/');
}

function countInstances(source: string, word: string): number {
	return source.split(word).length - 1;
}

export const getTemplateFromCurrentRoute = (url: string): TemplateTypeId => {
	switch (url.split('/')[1]) {
		case 'articolo':
			return TemplateTypeId.ARTICOLO;
		case 'video':
			return TemplateTypeId.VIDEO;
		case 'file':
			return TemplateTypeId.FILE;
		case 'dossier':
			return TemplateTypeId.DOSSIER;
		case 'canale':
			return TemplateTypeId.CANALE;
		case 'minisito':
			return TemplateTypeId.MINISITO;
		case 'ricerca':
			return TemplateTypeId.RICERCA;
		case 'home':
			return TemplateTypeId.HOME;
		case 'fad':
			return TemplateTypeId.FAD;
		case 'topic':
			return TemplateTypeId.TOPIC;
		case '':
			return TemplateTypeId.HOME;
	}
};
