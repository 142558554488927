import { createSelector } from '@ngrx/store';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { selectUserState } from '../../core/core.state';
import { getPermission } from '../../shared/util/util';
import { selectTemplateByID } from '../template-ct/template-ct.selectors';
import { UserState } from './user.model';

export const selectUser = createSelector(selectUserState, (state: UserState) => state.user);

export const selectUserId = createSelector(
	selectUserState,
	(state: UserState) => state.user?.idAnagrafica
);

export const selectUserPremium = createSelector(
	selectUserState,
	(state: UserState) => state.user?.premium
);

export const selectUserIsActivated = createSelector(
	selectUserState,
	(state: UserState) => state.user?.confermaMail
);

export const selectUserPermissionByContent = (abilitazioneContent: number) =>
	createSelector(
		selectUserIsActivated,
		selectAuthLoginState,
		// video template
		selectTemplateByID(4),
		(isUserActivated, authLoginState, template) =>
			getPermission(template.visibilita, authLoginState, abilitazioneContent, isUserActivated)
	);

export const selectUserError = createSelector(selectUserState, (state: UserState) => state.error);

export const selectUserOperationState = createSelector(
	selectUserState,
	(state: UserState) => state.userOperation
);
