import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { APIKEY } from '../../../../tokens';
import { LogService } from '../log/log.service';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {
	constructor(
		@Optional() @Inject(REQUEST) private node_req,
		@Optional() @Inject(RESPONSE) private node_res,
		@Optional() @Inject(APIKEY) private apiKey: string,
		private logService: LogService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		// If there's a response we are on the server-side && comes from a request that has cookies on it

		if (this.node_req) {
			// If optional request is provided, we are server side
			req = req.clone({
				setHeaders: {
					'API-KEY': this.apiKey
				}
			});
			this.logService.info('Interceptor, injecting APIKEY:', this.apiKey);
		}
		return next.handle(req);
	}
}
