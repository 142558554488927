/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '../../../environments/environment.service';

import { Content, MiniContent } from '../../domain/content/content.model';
import { MiniContainer } from '../../domain/dynamic-container/dynamic-container.model';

@Injectable({
	providedIn: 'root'
})
export class MetaService {
	constructor(
		private title: Title,
		private metaService: Meta,
		private environmentService: EnvironmentService
	) {}

	setMeta(content: Content | MiniContent | MiniContainer) {
		this.title.setTitle('DottNet - ' + content.titolo);
		let description: string;
		if (content?.descrizione && content.descrizione?.length > 0) {
			description = content.descrizione;
		} else {
			description = content.titolo;
		}

		if (content?.pathFoto?.length > 0) {
			// TODO: HERE it enters 4 times
			const image: string = this.environmentService.contentPath + content.pathFoto;
			this.metaService.updateTag({ property: 'og:image', content: image }, "property='og:image'");
			this.metaService.updateTag(
				{ property: 'og:image:height', content: '200' },
				"property='og:image:height'"
			);
			this.metaService.updateTag(
				{ property: 'og:image:width', content: '200' },
				"property='og:image:width'"
			);
		}
		if (content?.tag?.length > 0) {
			this.metaService.updateTag({ name: 'keywords', content: content.tag }, "name='keywords'");
		}

		this.metaService.updateTag(
			{ property: 'og:title', content: content.titolo },
			"property='og:title'"
		);
		this.metaService.updateTag(
			{ property: 'og:description', content: description },
			"property='og:description'"
		);
		this.metaService.updateTag(
			{ name: 'og:description', content: description },
			"name='og:description'"
		);
		// TODO: check if url needs absolute path
		this.metaService.updateTag(
			{ property: 'og:url', content: content.permalink },
			"property='og:url'"
		);
	}
}
