import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DottnetDialogComponent } from '../dn-dialog.component';

export interface ConsentInfoDialogData {
	consentType:
		| 'marketing'
		| 'marketingThirdParties'
		| 'profiling'
		| 'profilingThirdParties'
		| undefined;
	consentTitle?: string;
	consentBody?: string;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'dottnet-mat-dialog-consent-info',
	templateUrl: 'consent-info-dialog.component.html',
	styleUrls: ['consent-info-dialog.component.scss']
})
export class ConsentInfoDialogComponent extends DottnetDialogComponent<ConsentInfoDialogComponent> {
	constructor(
		private ts: TranslateService,
		dialogRef: MatDialogRef<ConsentInfoDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private consentsData: ConsentInfoDialogData,
		@Inject(PLATFORM_ID) platformId: Object,
		store: Store
	) {
		super(dialogRef, undefined, store,platformId);
	}

	public get consentType(): string {
		return this.consentsData.consentType;
	}

	public get consentTitle(): string {
		return this.consentsData?.consentTitle;
	}

	public get consentBody(): string {
		return this.consentsData?.consentBody;
	}

	getConsentsTitle(): string {
		return this.consentType
			? `Consenso ${this.ts.instant(`dottnet.label.consents.${this.consentType}`)}`
			: this.consentTitle;
	}

	getConsentsBody(): string {
		return this.consentType
			? `${this.ts.instant(`dottnet.dialog.consents.${this.consentType}`)}`
			: this.consentBody;
	}
}
