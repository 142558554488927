import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { DnDialogModule } from '../dn-dialog.module';

import { ImageViewerDialogComponent } from './image-viewer-dialog.component';

@NgModule({
	imports: [SharedModule, DnDialogModule],
	exports: [ImageViewerDialogComponent],
	declarations: [ImageViewerDialogComponent],
	providers: []
})
export class ImageViewerDialogModule {}
