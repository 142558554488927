export interface CookieDetail {
	name: string;
	description: string;
	duration: string;
}

export interface CookieItem {
	key: string;
	name: string;
	description: string;
	privacyPolicyUrl: string;
	cookies: CookieDetail[];
}

export interface CookieConsentState {
	functional_google_analytics: boolean;
	marketing_google_analytics: boolean;
	marketing_meta: boolean;
	status: boolean;
}

export enum GACookieAction {
	Granted = 'granted',
	Denied = 'denied'
}

export enum FBCookieAction {
	Granted = 'grant',
	Denied = 'revoke'
}
