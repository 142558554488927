import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { convertToParamMap, Params, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
	catchError,
	combineLatestWith,
	concatMap,
	exhaustMap,
	map,
	switchMap,
	tap
} from 'rxjs/operators';
import { ErrorMessage, ErrorType } from '../../domain/error/error-message.model';
import { manageError } from '../../domain/error/error.util';
import { loadUser, loadUserGuest, loadUserSoft, resetUser } from '../../domain/user/user.actions';
import { openDialog } from '../../shared/dialog/dn-dialog.actions';
import { PermissionDialogComponent } from '../../shared/dialog/permission-dialog/permission-dialog.component';
import { PermissionVisualizationType } from '../../shared/dialog/permission-dialog/permission.model';
import {
	getOneAndWaitforLoaded,
	getOneAndWaitforOnline,
	onlyWhenOnline
} from '../../shared/util/operators';
import { saveClickLog } from '../clicks/click.actions';
import { ClickLog, ClickOperation } from '../clicks/click.models';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { LogService } from '../log/log.service';
import { navigateHome, navigateOffline } from '../router/routes.actions';
import { createSession, resetSession } from '../session/session.actions';
import { selectSessionID } from '../session/session.selectors';
import {
	authCheckOnline,
	authGuestLogin,
	authGuestLoginFailure,
	authGuestLoginSuccess,
	authHardLogin,
	authHardLoginFailure,
	authHardLoginSuccess,
	authLoadingUser,
	authLogout,
	authLogoutAndGuest,
	authLogoutAndHard,
	authNotLogged,
	authOffline,
	authOnline,
	authRenewToken,
	authRenewTokenFailure,
	authRenewTokenSuccess,
	authSetLoginType,
	authSetRenewToken,
	authSoftLogin,
	authSoftLoginFailure,
	authSoftLoginSuccess,
	noOpAction,
	setLocalStorageAuth
} from './auth.actions';
import { AuthLoginState, HealthCheck, LoginType, ServerAuthData } from './auth.models';
import {
	selectAuthLoginState,
	selectGetAccessToken,
	selectGetRenewToken,
	selectIsAuthenticated
} from './auth.selectors';
import { AuthService } from './auth.service';

//  import {ServerAuthData} from './auth.models'
export const AUTH_KEY = 'AUTH';

@Injectable({ providedIn: 'root' })
export class AuthEffects {
	//  login hard
	login$ = createEffect(() => {
		const loginData: {
			email: string;
			password: string;
			rememberMe: boolean;
			loginType: LoginType;
		} = {
			email: '',
			password: '',
			rememberMe: false,
			loginType: LoginType.HARDLOGIN
		};

		return this.actions$.pipe(
			ofType(authHardLogin),
			onlyWhenOnline(this.store),
			getOneAndWaitforLoaded(this.store),
			tap((action) => this.logService.info('Effect: authLogin', action)),
			switchMap((action) => {
				loginData.email = action.email;
				loginData.password = action.password;
				loginData.rememberMe = action.rememberMe;
				loginData.loginType = action.loginType;

				return this.authService
					.login(action.email, action.password, action.rememberMe, action.loginType)
					.pipe(
						map((serverAuthData) => authHardLoginSuccess({ serverAuthData })),
						catchError((error: any) => {
							// create click log on login failure
							const clickLog: ClickLog = <ClickLog>{};
							clickLog.clickLogOperationID = ClickOperation.Login;
							clickLog.content = JSON.stringify(loginData);
							clickLog.message = error.messageDN;
							clickLog.method = 'POST';
							clickLog.outcome = false;
							this.store.dispatch(saveClickLog({ clickLog }));

							return of(authHardLoginFailure({ error }));
						})
					);
			})
		);
	});

	loginSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(authHardLoginSuccess),
				tap((payload) => {
					this.logService.info('Effect: authHardLoginSuccess. ', payload.serverAuthData);
					this.storageService.setItem('RENEW', payload.serverAuthData.RenewToken);
					this.storageService.setItem('LOGINTYPE', LoginType.HARDLOGIN);
					// create click_log on login success
					const clickLog: ClickLog = <ClickLog>{};
					clickLog.clickLogOperationID = ClickOperation.Login;
					clickLog.content = '';
					clickLog.message = 'Login OK';
					clickLog.method = 'POST';
					clickLog.outcome = true;
					this.store.dispatch(saveClickLog({ clickLog }));
				}),
				concatMap((payload) => [
					loadUser({ idAnagrafica: payload.serverAuthData.IDAnagrafica }),
					authLoadingUser()
				])
			)
		//  { dispatch: false },
	);

	//  guest login
	guestLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authGuestLogin),
			onlyWhenOnline(this.store),
			getOneAndWaitforLoaded(this.store),
			tap((action) => this.logService.info('Effect: authGuestLogin', action)),
			switchMap(() =>
				this.authService.login('', '', true, LoginType.GUESTLOGIN).pipe(
					tap((serverAuthData: ServerAuthData) =>
						this.logService.info('After guest login. Received:', serverAuthData)
					),
					map((serverAuthData: ServerAuthData) => authGuestLoginSuccess({ serverAuthData })),
					catchError((error: any) => of(authGuestLoginFailure({ error })))
				)
			)
		)
	);

	guestLoginFailure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authGuestLoginFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap(([payload, authLoginState]) => {
				this.logService.info('Effect: authGuestLoginFailure', payload.error);
			}),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	guestLoginSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authGuestLoginSuccess),
			tap((payload) => {
				this.logService.info('Effect: authGuestLoginSuccess. ', payload.serverAuthData);
				this.storageService.setItem('RENEW', payload.serverAuthData.RenewToken);
				this.storageService.setItem('LOGINTYPE', LoginType.GUESTLOGIN);
			}),
			map((payload) => loadUserGuest({})),
			catchError((error: any) => of(authGuestLoginFailure({ error })))
		)
	);

	// renew
	renew$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authRenewToken),
			onlyWhenOnline(this.store),
			getOneAndWaitforLoaded(this.store),
			tap((action) => this.logService.info('Effect: authRenewToken', action)),
			concatLatestFrom(() => this.store.select(selectGetRenewToken)),
			exhaustMap(
				([_, renewToken]) =>
					this.authService.renewToken(renewToken).pipe(
						map((serverAuthData) => authRenewTokenSuccess({ serverAuthData: serverAuthData })),
						catchError((error: any) => of(authRenewTokenFailure({ error })))
					) //  pipe renewToken
			) //  exhaustmap
		)
	);

	renewSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authRenewTokenSuccess),
			tap((payload) => {
				this.logService.info('Effect: authRenewSuccess ', payload.serverAuthData);
				//  token has been successfully renewed
				//  storage LOGINTYPE doesn't change because we just renewed it
				this.storageService.setItem('RENEW', payload.serverAuthData.RenewToken);
			}),
			map((payload) => {
				switch (payload.serverAuthData.loginType) {
					case LoginType.GUESTLOGIN:
						return loadUserGuest({});
					case LoginType.HARDLOGIN:
						return loadUser({ idAnagrafica: payload.serverAuthData.IDAnagrafica });
					case LoginType.SOFTLOGIN:
						return loadUserSoft({ idAnagrafica: payload.serverAuthData.IDAnagrafica });
					default:
						const errorMessage: ErrorMessage = {
							Code: ErrorType.ErrorTipoLoginScorretto,
							message: 'Incorrect Login type',
							messageDN: 'Incorrect Login type',
							name: 'Incorrect Login type'
						};
						return authRenewTokenFailure({ error: errorMessage });
				}
			}),
			catchError((error: any) => of(authRenewTokenFailure({ error })))
		)
	);

	setRenewToken$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authSetRenewToken),
			tap((action) => this.logService.info('Effect: authSetRenewToken', action)),
			map((action) => authRenewToken()),
			catchError((error: any) => of(authRenewTokenFailure({ error })))
		)
	);

	//  login soft
	softLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authSoftLogin),
			onlyWhenOnline(this.store),
			getOneAndWaitforLoaded(this.store),
			tap((action) => this.logService.info('Effect: authLoginSoft', action)),
			switchMap((action) =>
				this.authService.softLogin(action.token).pipe(
					map((serverAuthData) => authSoftLoginSuccess({ serverAuthData })),
					catchError((error: any) => of(authSoftLoginFailure({ error })))
				)
			)
		)
	);

	//  check if online
	authCkeckOnline$ = createEffect(() => {
		let savedAuthLoginState: AuthLoginState = AuthLoginState.LOGGEDGUEST;
		return this.actions$.pipe(
			ofType(authCheckOnline),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap(([_, authLoginState]) => {
				savedAuthLoginState = authLoginState;

				this.logService.info('Effect: authCheckOnline');
			}),
			switchMap((action) =>
				this.authService.checkOnline().pipe(
					map((healthCheck: HealthCheck) => {
						this.logService.info('authCheckOnline calling authOnline:', healthCheck);
						return authOnline();
					}),
					catchError((error: any) => {
						this.logService.info('authCheckOnline calling manageError:', error);
						return of(manageError(error, savedAuthLoginState));
					})
				)
			)
		);
	});

	loginSoftSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(authSoftLoginSuccess),
				tap((payload) => {
					this.logService.info('Effect: authSoftLoginSuccess. ', payload.serverAuthData);
					this.storageService.setItem('RENEW', payload.serverAuthData.RenewToken);
					this.storageService.setItem('LOGINTYPE', LoginType.SOFTLOGIN);
				}),
				concatMap((payload) => [
					authLoadingUser(),
					loadUserSoft({ idAnagrafica: payload.serverAuthData.IDAnagrafica })
				]),
				catchError((error: any) => of(authSoftLoginFailure({ error })))
			)
		//  { dispatch: false },
	);

	online$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authOnline),
			// first time backend goes offline let authOffline execute.url
			// then filter all subsequent requests until backend is ONLINE again
			onlyWhenOnline(this.store),
			tap(() => {
				this.logService.info('Effect: authOnline. Server back online', undefined);
			}),
			combineLatestWith(this.store.select(selectIsAuthenticated)),
			map(([_, isAuthenticated]) => {
				if (!isAuthenticated) {
					return authGuestLogin();
				} else {
					return noOpAction();
				}
			})
		)
	);

	offline$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authOffline),
			// first time backend goes offline let authOffline execute.url
			// then filter all subsequent requests until backend is ONLINE again
			getOneAndWaitforOnline(this.store),
			tap(() => {
				this.logService.info('Effect: authOffline. Server went offline', undefined);
			}),
			// Needed to create a copy of the router.url.
			// If omitted, we would pass the reference to this.router.url which would change
			// POC: remove 448, change currentUrl to { currentUrl: this.router.url } and tapping the value in navigateOffline
			map(() => this.router.url),
			map((currentUrl) => navigateOffline({ currentUrl }))
		)
	);

	// logout

	logout = createEffect(() =>
		this.actions$.pipe(
			ofType(authLogout),
			// let go only first call, then waits until internal observable emits. This way if there are many calls to the same effect, only one is executed
			onlyWhenOnline(this.store),
			getOneAndWaitforLoaded(this.store),
			concatLatestFrom(() => this.store.select(selectGetAccessToken)),
			tap((action) => {
				this.logService.info('Effect: authLogout', action);
				this.storageService.removeItem('RENEW');
				this.storageService.removeItem('LOGINTYPE');
				this.storageService.removeItem('USER');
			}),
			switchMap(([_, accessToken]) =>
				this.authService.logout(accessToken).pipe(
					tap((serverAuthData: ServerAuthData) => {
						this.logService.info('After logout. Received:', serverAuthData);
						const clickLog: ClickLog = <ClickLog>{};
						clickLog.clickLogOperationID = ClickOperation.Logout;
						clickLog.content = '';
						clickLog.message = 'Logout OK';
						clickLog.method = 'GET';
						clickLog.outcome = true;
						this.store.dispatch(saveClickLog({ clickLog }));
					}),
					concatLatestFrom(() => this.store.select(selectSessionID)),
					concatMap(([serverAuthData, oldSessionID]) => [
						navigateHome(),
						resetUser(),
						resetSession(),
						authGuestLoginSuccess({ serverAuthData }),
						createSession({
							params: convertToParamMap(<Params>{}),
							oldSessionID: oldSessionID,
							url: this.router.url,
							referrer: this.ssrDocument.referrer
						})
					]),
					catchError((error: any) => of(authGuestLoginFailure({ error })))
				)
			)
		)
	);

	loginerror = createEffect(() =>
		this.actions$.pipe(
			ofType(authRenewTokenFailure, authHardLoginFailure, authSoftLoginFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap((action) => this.logService.info('Effect: ', action)),
			map(([action, authLoginState]) => {
				//  if I am already Guest, no need to guest login again
				if (authLoginState === AuthLoginState.LOGGEDGUEST) {
					return loadUserGuest({});
				}

				// TODO: here we need to evaluate action.type and authLoginState
				// if authLoginState is NOLOGGED, we need to pass something else as state
				// so that manageError knows what action trigger
				// So if action.type is renewTokenFailure we need to check LOGINTYPE localstorage element
				// if action.type is authHardLoginFailure we need to pass HARDLOGGED
				// if action.type is authSoftLoginFailure we need to pass SOFTLOGGED

				if (authLoginState === AuthLoginState.NOTLOGGED) {
					if (action.type === authRenewTokenFailure.type) {
						const currentLoginType = <LoginType>this.storageService.getItem('LOGINTYPE');
						switch (currentLoginType) {
							case LoginType.GUESTLOGIN:
								authLoginState = AuthLoginState.LOGGEDGUEST;
								break;
							case LoginType.HARDLOGIN:
								authLoginState = AuthLoginState.LOGGEDHARD;
								break;
							case LoginType.SOFTLOGIN:
								authLoginState = AuthLoginState.LOGGEDSOFT;
								break;
						}
					}
					if (action.type === authHardLoginFailure.type) {
						authLoginState = AuthLoginState.LOGGEDHARD;
					}
					if (action.type === authSoftLoginFailure.type) {
						authLoginState = AuthLoginState.LOGGEDSOFT;
					}
				}

				this.storageService.removeItem('RENEW');
				this.storageService.removeItem('USER');
				this.storageService.removeItem('LOGINTYPE');
				this.logService.info('Effect: authLogin/Renew failure. dispatching guest login', undefined);

				return manageError(action.error, authLoginState);
			})
			/* ,
			catchError((error: any) => of(manageError(error)))
		*/
		)
	);

	setLocalStorageAuth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setLocalStorageAuth),
			tap(() => this.logService.info('Effect: setLocalStorageAuth')),
			concatMap(({ token, loginType }) => [
				authSetRenewToken({ token }),
				authSetLoginType({ loginType })
			])
		)
	);

	authLogoutandGuest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authLogoutAndGuest),
			tap(() => this.logService.info('Effect: authLogoutAndGuest')),
			concatMap(() => [authNotLogged(), resetUser(), resetSession(), authGuestLogin()])
		)
	);

	authLogoutandHard$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authLogoutAndHard),
			tap(() => this.logService.info('Effect: authLogoutAndHard')),
			concatMap(() => [
				authNotLogged(),
				resetUser(),
				resetSession(),
				authGuestLogin(),
				openDialog({
					content: undefined,
					componentType: PermissionDialogComponent,
					disableClose: true,
					data: {
						permissionType: PermissionVisualizationType.UserSessionExpired
					}
				})
			])
		)
	);

	/*	authNotLogged$$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authNotLogged),
			tap(() => this.logService.info('Effect: authNotLogged')),
			map(() =>	resetUser())
		)
	);
*/
	constructor(
		private actions$: Actions,
		private authService: AuthService,
		private router: Router,
		private storageService: LocalStorageService,
		private store: Store,
		private logService: LogService,
		@Inject(DOCUMENT) private ssrDocument: Document
	) {}
}
