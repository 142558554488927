import { ActionReducer, MetaReducer } from '@ngrx/store';
import { LogLevel } from '../../domain/log/log.model';
import { AppState } from '../core.state';
import { LogService } from '../log/log.service';

/* export function debug(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function (state, action) {
    const newState = reducer(state, action);
    console.log(`[DEBUG] action: ${action.type}`, {
      payload: (<any>action).payload,
      oldState: state,
      newState
    });
    return newState;
  };
}
*/

export function metaReducerFactory(logService: LogService): MetaReducer<AppState> {
	return (reducer: ActionReducer<any>) => (state, action) => {
		const newState = reducer(state, action);
		if (
			!action.type.startsWith('@ngrx/store-devtools') &&
			!action.type.startsWith('@ngrx/store/update-reducers') &&
			!action.type.startsWith('[DynamicContainer] getCssSuccess')
		) {
			const payload = new Map<string, any>();
			Object.keys(action).forEach((key) => {
				if (key !== 'type') {
					payload.set(key, action[key]);
				}
			});
			logService.infoTrace('Dispatch: ' + action.type, payload, {
				payload: payload,
				oldState: state,
				newState
			});

			/*				logService.debug('Dispatch: ' + action.type, {
					payload: payload,
					oldState: state,
					newState
				});
			} else {
				logService.info('Dispatch: ' + action.type);
			}
			*/
		}
		return newState;
	};
}
