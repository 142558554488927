import { EnvironmentService } from './../../../environments/environment.service';
import {
	Component,
	OnInit,
	OnDestroy,
	Inject,
	PLATFORM_ID,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { LogService } from '../log/log.service';
import { Store } from '@ngrx/store';
import { setCookieConsent } from './cookie-consent.actions';
import { isPlatformBrowser } from '@angular/common';
import { CookieConsentState } from './cookie-consent.model';

/*
 This component needs to be refactored with actions, effects and selectors.
 TBD
 */

@Component({
	selector: 'dottnet-cookie-consent',
	templateUrl: './cookie-consent.component.html',
	styleUrls: ['./cookie-consent.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieConsentComponent implements OnInit {
	cookieConsentVisible = false;
	showSettingsDialog = false;
	dropDownOpen = false;
	functionalCookiesClosed = true;
	marketingCookiesClosed = true;
	essentialCookiesClosed = true;
	cookieForm: FormGroup;
	private cookieFields: {
		functional: { key: string; selected: boolean }[];
		marketing: { key: string; selected: boolean }[];
	};

	constructor(
		private store: Store,
		private router: Router,
		private consentService: CookieConsentService,
		private formBuilder: FormBuilder,
		private logService: LogService,
		private changeDetector: ChangeDetectorRef,
		private enviromentService: EnvironmentService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		this.cookieFields = this.consentService.getCookieFields();
		this.cookieForm = this.buildForm();
	}

	get privacyPolicyUrl(): string {
		const config = this.enviromentService.privacyPolicyUrl;
		return config;
	}

	get imprintUrl(): string {
		const config = this.enviromentService.imprintUrl;
		return config;
	}

	get functionalCookiesAllSelected(): boolean {
		const arr = Object.values(this.cookieForm.get('functional')?.value);

		if (arr.length === 0) {
			return false;
		}

		return arr.every((value) => value === true);
	}

	get marketingCookiesAllSelected(): boolean {
		const arr = Object.values(this.cookieForm.get('marketing')?.value);

		if (arr.length === 0) {
			return false;
		}

		return arr.every((value) => value === true);
	}

	config(key: string) {
		return this.consentService.getConfig(key);
	}

	toggle($event: any, category: string) {
		const fields: any = this.consentService.getCookieFields();
		const cookies = fields[category];
		cookies.forEach((field: any) => {
			this.cookieForm.get(category)?.get(field.key)?.setValue($event.currentTarget.checked);
		});
	}

	back() {
		this.showSettingsDialog = false;
		this.resetDropdowns();
		this.resetForm();
	}

	denyAllCookies() {
		this.resetModal();
		this.consentService.denyAllCookies();
		this.setCookieState();
		this.resetForm();
	}

	acceptAllCookies() {
		this.resetModal();
		this.consentService.acceptAllCookies();
		this.setCookieState();
		this.resetForm();
	}

	saveSomeCookies() {
		this.consentService.saveSomeCookies(this.cookieForm.value);
		this.setCookieState();
		this.resetModal();
	}

	private buildForm(): FormGroup {
		return this.formBuilder.group({
			functional: this.buildCookieFields(this.cookieFields.functional),
			marketing: this.buildCookieFields(this.cookieFields.marketing)
		});
	}

	private buildCookieFields(fields: { key: string; selected: boolean }[]) {
		const group: any = {};
		fields.forEach((field) => {
			group[field.key] = this.formBuilder.control(field.selected);
		});

		return this.formBuilder.group(group);
	}

	private resetForm() {
		const fields: any = this.consentService.getCookieFields();
		const categoryKeys = Object.keys(fields);
		categoryKeys.forEach((categoryKey) => {
			const category = fields[categoryKey];
			category.forEach((field: any) => {
				this.cookieForm.get(categoryKey)?.get(field.key)?.setValue(field.selected);
			});
		});
	}

	private resetDropdowns() {
		this.functionalCookiesClosed = true;
		this.marketingCookiesClosed = true;
		this.essentialCookiesClosed = true;
	}

	private resetModal() {
		this.cookieConsentVisible = false;
		this.showSettingsDialog = false;
		this.resetDropdowns();
		this.changeDetector.detectChanges();
	}

	ngOnInit(): void {
		this.setCookieState();

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
			next: (event: any) => {
				if (
					isPlatformBrowser(this.platformId) &&
					// exclude imprintUrl and privacyPolicyUrl from conset request
					event.url !== this.imprintUrl &&
					event.url !== this.privacyPolicyUrl
				) {
					this.cookieConsentVisible = this.consentService.shouldDisplayCookieConsent();
				} else {
					this.cookieConsentVisible = false;
				}
				this.changeDetector.detectChanges();
			}
		});
	}

	setCookieState() {
		const cookieConsent: CookieConsentState = {
			functional_google_analytics: this.consentService.getCookie('functional_google_analytics'),
			marketing_google_analytics: this.consentService.getCookie('marketing_google_analytics'),
			marketing_meta: this.consentService.getCookie('marketing_meta'),
			status: this.consentService.getCookie('status')
		};

		const cookieConsentPayload = { cookieConsent: cookieConsent };
		this.store.dispatch(setCookieConsent(cookieConsentPayload));
	}

	closeDropDown($event: Event) {
		const eventTarget = $event.target as HTMLElement;
		const parentTarget = eventTarget.parentElement;

		if (
			eventTarget.classList.contains('language-chooser') ||
			parentTarget.classList.contains('language-chooser')
		) {
			return;
		}

		this.dropDownOpen = false;
	}
}
