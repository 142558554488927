import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { templateCtReducer } from '../domain/template-ct/template-ct.reducer';
import * as topic from '../domain/topic/topic.reducer';
import { TEMPLATE_FEATURE_NAME, TOPIC_FEATURE_NAME } from './shared.state';
import { EffectsModule } from '@ngrx/effects';
import { TopicEffects } from '../domain/topic/topic.effects';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		StoreModule.forFeature(TEMPLATE_FEATURE_NAME, templateCtReducer),
		RouterModule,
		StoreModule.forFeature(TOPIC_FEATURE_NAME, topic),
		EffectsModule.forFeature([TopicEffects])
	],
	declarations: [],
	exports: [
		// Rendo disponibili i moduli standard
		CommonModule,
		RouterModule,
		TranslateModule
	],
	providers: []
})
export class SharedModule {
	constructor() {}
}
