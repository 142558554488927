import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SpinnerModule } from '../../spinner/spinner.module';
import { DnDialogModule } from '../dn-dialog.module';
import { SigninDialogComponent } from './signin-dialog.component';
import { SigninFormModule } from './signin-form/signin-form.module';

@NgModule({
	imports: [SharedModule, DnDialogModule, SigninFormModule, SpinnerModule],
	exports: [SigninDialogComponent],
	declarations: [SigninDialogComponent],
	providers: []
})
export class SigninDialogModule {}
