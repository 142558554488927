import { Subscription } from 'rxjs';
import { getEndpointFor } from './routes-util';
import { PremiumSubscription } from '../../domain/extra/extra.model';

/**
 *
 *
 * I parametri sono sostituiti al simbolo $ nell'ordine in cui vengono passati al metodo getEndpointFor(...)
 *
 */

// Contents

export function getCommentsByContentIdUrl(
	commentId: number,
	userId: number,
	template: string
): string {
	return getEndpointFor('content', 'commentsbyid', commentId, userId, template);
}

export function getInsertCommentUrl(
	parentId: number,
	parentTemplate: string,
	title: string,
	text: string,
	authorId: number,
	attachmentId: number,
	attachmentTypeId: number,
	template: string,
	clientSessionId: number
): string {
	return getEndpointFor(
		'content',
		'insertcomment',
		parentId,
		parentTemplate,
		title,
		text,
		authorId,
		attachmentId,
		attachmentTypeId,
		template,
		clientSessionId
	);
}

export function getUploadTemporaryMediaUrl(userId: number): string {
	return getEndpointFor('content', 'temporarymedia', userId);
}

export function getInsertMediaAndCommentUrl(
	parentId: number,
	parentTemplate: string,
	title: string,
	text: string,
	commentTemplate: string,
	clientSessionId: number,
	userId: number,
	templateId: number,
	contentTypeId: number,
	fileName: string,
	templateDescription: string
): string {
	return getEndpointFor(
		'content',
		'insertmediaandcomment',
		parentId,
		parentTemplate,
		title,
		text,
		commentTemplate,
		clientSessionId,
		userId,
		templateId,
		contentTypeId,
		fileName,
		templateDescription
	);
}

export function getContentsRelatedUrl(
	category: number,
	template: string,
	contentId: number,
	contextId: number,
	userId: number,
	fromWhere: number,
	howMany: number
): string {
	return getEndpointFor(
		'content',
		'related',
		category,
		template,
		contentId,
		contextId,
		userId,
		fromWhere,
		howMany
	);
}

export function getContentsMostReadUrl(userId: number, fromWhere: number, howMany: number): string {
	return getEndpointFor('content', 'mostread', userId, fromWhere, howMany);
}

export function getContentsLatestNewsUrl(
	userId: number,
	fromWhere: number,
	howMany: number
): string {
	return getEndpointFor('content', 'latestnews', userId, fromWhere, howMany);
}

export function getLatestVideosUrl(userId: number, fromWhere: number, howMany: number): string {
	return getEndpointFor('content', 'latestvideos', userId, fromWhere, howMany);
}

export function getContentsCorrelatiByCategorieUrl(
	template: string,
	listaCategorie: string,
	userId: number,
	contentId: number,
	fromWhere: number,
	howMany: number
): string {
	return getEndpointFor(
		'content',
		'correlatibycategorie',
		template,
		listaCategorie,
		userId,
		contentId,
		fromWhere,
		howMany
	);
}

export function getHighlightByIdRubricaEvidenzaUrl(
	idRubrica: number,
	isDetail: number,
	userId: number,
	template: string,
	idCategory: number
): string {
	return getEndpointFor('content', 'highlight', idRubrica, isDetail, userId, template, idCategory);
}

export function getHighlightByIdRubricaUrl(
	idRubrica: number,
	isDetail: number,
	userId: number,
	template: string,
	fromWhere: number,
	howMany: number
): string {
	return getEndpointFor(
		'content',
		'highlight-list',
		idRubrica,
		isDetail,
		userId,
		template,
		fromWhere,
		howMany
	);
}

// Auth

export function getAuthLoginUrl(): string {
	return getEndpointFor('auth', 'login');
}

export function getAuthSoftTokenUrl(): string {
	return getEndpointFor('auth', 'softtoken');
}

export function getAuthSoftLoginUrl(): string {
	return getEndpointFor('auth', 'softlogin');
}

export function getAuthLogoutUrl(): string {
	return getEndpointFor('auth', 'logout');
}

export function getAuthRenewUrl(): string {
	return getEndpointFor('auth', 'renew');
}

// Session

export function getCreateSessionUrl(): string {
	return getEndpointFor('session', 'create');
}

export function getCreatePageViewUrl(): string {
	return getEndpointFor('session', 'createpageview');
}

export function getCreateClickLogUrl(): string {
	return getEndpointFor('session', 'createclicklog');
}

// User

export function getRegisterUserUrl(): string {
	return getEndpointFor('user', 'register');
}

export function getActivateUserUrl(token: string): string {
	return getEndpointFor('user', 'activate', token);
}

export function getResendActivationMailUrl(email: string, userId: number): string {
	return getEndpointFor('user', 'resendMail', email, userId);
}

export function getRetrievePassword(email: string): string {
	return getEndpointFor('user', 'retrievepassword', email);
}

export function getChangePassword() {
	return getEndpointFor('user', 'changepassword');
}

export function getUpdateUserUrl(): string {
	return getEndpointFor('user', 'update');
}

export function getUserByIdUrl(userId: number): string {
	return getEndpointFor('user', 'getbyid', userId);
}

export function getCheckUserMailUrl(mail: string, userId: number): string {
	return getEndpointFor('user', 'checkMail', mail, userId);
}

export function getCheckCFUrl(cf: string, userId: number): string {
	return getEndpointFor('user', 'checkCF', cf, userId);
}

// Container

export function getContainerById(userId: number, containerId: number): string {
	return getEndpointFor('container', 'getbyid', containerId, userId);
}

// Content

export function getContentByIdUrl(contentId: number, userId: number, template: string): string {
	return getEndpointFor('content', 'getbyid', contentId, userId, template);
}

export function getLoadChildrenFromCollectionUrl(
	contentId: number,
	detail: number,
	userId: number
): string {
	return getEndpointFor('content', 'children', contentId, detail, userId);
}

// Log
export function getSaveLogsUrl(sessionGuid: string) {
	return getEndpointFor('log', 'save', sessionGuid);
}

// content list
export function getContentListUrl(
	template: string,
	userId: number,
	fromWhere: number,
	howMany: number,
	premium: boolean
): string {
	return getEndpointFor('content', 'list', template, userId, fromWhere, howMany, premium);
}

// content list
export function getContentListByCategorytUrl(
	categoryId: number,
	userId: number,
	fromWhere: number,
	howMany: number,
	premium: boolean
): string {
	return getEndpointFor('content', 'topic', categoryId, userId, fromWhere, howMany, premium);
}

// content list
export function getContentSearchListUrl(
	userId: number,
	searchText: string,
	fromWhere: number,
	howMany: number
): string {
	return getEndpointFor('content', 'search', userId, searchText, fromWhere, howMany);
}

// Wall

export function getWallCampiGruppiProfiloUrl(userId: number, groups: string): string {
	return getEndpointFor('wall', 'campigruppimuroprofilo', userId, groups);
}

// Category

export function getCategoriesUrl(): string {
	return getEndpointFor('category', 'getall');
}

// Province

export function getProvincesUrl(): string {
	return getEndpointFor('province', 'getall');
}

// Specialty

export function getSpecialtiesUrl(): string {
	return getEndpointFor('specialty', 'getall');
}

// City

export function getCitiesByPrefixUrl(prefix: string) {
	return getEndpointFor('city', 'byprefix', prefix);
}

// Topic

export function getTopicUrl(): string {
	return getEndpointFor('topic', 'getall');
}
// Template

export function getTemplatectUrl(): string {
	return getEndpointFor('templatect', 'getall');
}

// Machform

export function getFormByIdUrl(formId: number): string {
	return getEndpointFor('machform', 'getbyid', formId);
}

// Segui
export function getSeguiUrl(
	idAnagrafica: number,
	idContesto: number,
	idTipoContesto: number,
	clientSessionId: number
): string {
	return getEndpointFor(
		'container',
		'getsottoscrizione',
		idAnagrafica,
		idContesto,
		idTipoContesto,
		clientSessionId
	);
}

// DrClick

export function getClientInfoByIdUrl(clientId: string): string {
	return getEndpointFor('drclick', 'clientbyid', clientId);
}

export function checkExternalConsentsUrl(clientId: string, userId: number): string {
	return getEndpointFor('drclick', 'checkconsents', clientId, userId);
}

export function saveExternalConsentsUrl(): string {
	return getEndpointFor('drclick', 'saveconsents');
}

// Zoom
export function getSignatureUrl(meetingNumber: string, role: number) {
	return getEndpointFor('zoom', 'getsignature', meetingNumber, role);
}

// Disiscrizioni
export function getdisiscrizioneNewsletterUrl(anc: string, idNewsletter: number): string {
	return getEndpointFor('settings', 'disiscrizionenewsletter', anc, idNewsletter);
}

export function getNewsletterInfoUrl(anc: string, idNewsletter: number): string {
	return getEndpointFor('settings', 'newsletterInfo', anc, idNewsletter);
}

export function getdisiscrizioneNewsletterAllUrl(anc: string): string {
	return getEndpointFor('settings', 'disiscrizionenewsletterall', anc);
}

export function getdisiscrizioneDottnetUrl(anc: string): string {
	return getEndpointFor('settings', 'disiscrizionedottnet', anc);
}

// premium
export function getPremiumModulesUrl(userId: number, forcedCampaigns: string): string {
	return getEndpointFor('premium', 'modules', userId, forcedCampaigns);
}
export function checkPremiumTokenUrl(token: string, userId: number): string {
	return getEndpointFor('premium', 'checktoken', token, userId);
}
export function getMyPremiumUrl(userId: number): string {
	return getEndpointFor('premium', 'mypremium', userId);
}

export function getDiscountTypeUrl(): string {
	return getEndpointFor('premium', 'discountType');
}

export function setSubscriptionUrl(): string {
	return getEndpointFor('premium', 'setAbbonamento');
}

export function createCouponUrl(idTipoSconto: number, userId: number): string {
	return getEndpointFor('premium', 'createCoupon', idTipoSconto, userId);
}

export function updateSubscription(
	moduleId: number,
	automaticRenewal: boolean,
	idTipoSconto: number,
	subscriptionId: number
): string {
	return getEndpointFor(
		'premium',
		'updateModule',
		moduleId,
		automaticRenewal,
		idTipoSconto,
		subscriptionId
	);
}
