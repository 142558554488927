import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LogLevel } from '../../domain/log/log.model';
import { ErrorMessage, errorMessages } from '../../domain/error/error-message.model';
import { NotificationDN, SeverityDN, SnackbarStyles } from './notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService {
	constructor(
		private readonly snackBar: MatSnackBar,
		private readonly zone: NgZone,
		private ts: TranslateService
	) {}

	showNotificationSnackbar(notification: NotificationDN) {
		this.snackBar.open(
			`${notification.code} - ${notification.message}`,
			notification.action,
			notification.config
		);
	}

	// Generic notification

	getNotification(
		severity: SeverityDN,
		code: number,
		message: string,
		action: string,
		durationSeconds: number,
		snackbarStyle: SnackbarStyles
	): NotificationDN {
		return new NotificationDN(severity, code, message, action, durationSeconds, snackbarStyle);
	}

	// Utility methods for common notifications

	getOkNotification(
		code: number = 0,
		message: string = this.ts.instant('dottnet.notification.success'),
		action: string = this.ts.instant('dottnet.notification.ok'),
		durationSeconds: number = 0,
		snackbarStyle: SnackbarStyles = SnackbarStyles.SUCCESS
	) {
		return new NotificationDN(
			SeverityDN.SUCCESS,
			code,
			message,
			action,
			durationSeconds,
			snackbarStyle
		);
	}

	getInfoNotification(
		code: number = 0,
		message: string = this.ts.instant('dottnet.notification.info'),
		action: string = this.ts.instant('dottnet.notification.ok'),
		durationSeconds: number = 0,
		snackbarStyle: SnackbarStyles = SnackbarStyles.INFO
	) {
		return new NotificationDN(
			SeverityDN.SUCCESS,
			code,
			message,
			action,
			durationSeconds,
			snackbarStyle
		);
	}

	getErrorNotification(
		code: number = -100,
		message: string = this.ts.instant('dottnet.notification.error'),
		action: string = this.ts.instant('dottnet.notification.ok'),
		durationSeconds: number = 0,
		snackbarStyle: SnackbarStyles = SnackbarStyles.ERROR
	) {
		return new NotificationDN(
			SeverityDN.ERROR,
			code,
			message,
			action,
			durationSeconds,
			snackbarStyle
		);
	}

	getNotificationFromError(error: ErrorMessage) {
		if (errorMessages.has(error.Code)) {
			return this.getErrorNotification(error.Code, this.ts.instant(errorMessages.get(error.Code)));
		} else {
			return this.getErrorNotification(error.Code, error.messageDN);
		}
	}

	showLog(message: string, logLevel: LogLevel): void {
		let configuration: MatSnackBarConfig;
		switch (logLevel) {
			case LogLevel.ERROR:
				configuration = { duration: 3000, panelClass: 'error-notification-overlay' };
				break;
			case LogLevel.INFO:
				configuration = { duration: 2000, panelClass: 'info-notification-overlay' };
				break;
			case LogLevel.WARN:
				configuration = { duration: 2500, panelClass: 'warning-notification-overlay' };
				break;
			case LogLevel.DEBUG:
				configuration = { duration: 3000, panelClass: 'warning-notification-overlay' };
				break;
			case LogLevel.TRACE:
				configuration = { duration: 3000, panelClass: 'warning-notification-overlay' };
				break;
		}

		this.zone.run(() => this.snackBar.open(message, null, configuration));
	}
}
