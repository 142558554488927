import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MailConfirmModule } from '../../mail-confirm/mail-confirm.module';
import { SharedModule } from '../../shared.module';
import { DnDialogModule } from '../dn-dialog.module';
import { SigninFormModule } from '../signin-dialog/signin-form/signin-form.module';
import { PermissionDialogComponent } from './permission-dialog.component';

@NgModule({
	imports: [SharedModule, DnDialogModule, MailConfirmModule, SigninFormModule],
	exports: [PermissionDialogComponent],
	declarations: [PermissionDialogComponent],
	providers: []
})
export class PermissionDialogModule {}
