import { NgModule } from '@angular/core';

import { VideoViewerComponent } from './video-viewer.component';

@NgModule({
	imports: [],
	exports: [VideoViewerComponent],
	declarations: [VideoViewerComponent],
	providers: []
})
export class VideoViewerModule {}
