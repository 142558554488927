import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	imports: [FontAwesomeModule, MatDialogModule, MatButtonModule],
	exports: [FontAwesomeModule, MatDialogModule, MatButtonModule],
	declarations: [],
	providers: []
})
export class DnDialogModule {}
