import { ErrorMessage } from '../error/error-message.model';
import { DNOperationState } from '../../shared/util/util';

export const CHUNK_SIZE = 150;

export enum LogLevel {
	ERROR = 0,
	WARN = 1,
	INFO = 2,
	DEBUG = 3,
	TRACE = 4
}

export function logLevelToString(level: LogLevel): string {
	switch (level) {
		case LogLevel.ERROR:
			return 'ERROR';
		case LogLevel.WARN:
			return 'WARN ';
		case LogLevel.INFO:
			return 'INFO ';
		case LogLevel.DEBUG:
			return 'DEBUG';
		case LogLevel.TRACE:
			return 'TRACE';
	}
}

export function logStringToValue(level: string): LogLevel {
	switch (level.toLocaleLowerCase().trim()) {
		case 'error':
			return LogLevel.ERROR;
		case 'warn':
			return LogLevel.WARN;
		case 'info':
			return LogLevel.INFO;
		case 'debug':
			return LogLevel.DEBUG;
		case 'trace':
			return LogLevel.TRACE;
	}
}

export class LogEntry {
	id: number;
	idAnagrafica: number;
	rowType: string = '';
	// Unix format date in milliseconds
	messageDate: number = 0;
	code: number = 0;
	message: string = '';
	furtherData: string = '';
	sessionGuid: string;
	platform: string

	constructor(
		idAnagrafica: number,
		rowType: string,
		messageDate: number,
		message: string,
		code: number,
		furtherData?: string,
		id?: number,
		platform? : string
	) {
		this.idAnagrafica = idAnagrafica;
		this.rowType = rowType;
		this.messageDate = messageDate;
		this.code = code || 0;
		this.message = message;
		this.furtherData = furtherData || '';
		if (id) this.id = id;
		this.platform = platform? platform : 'C';
	}
}

export interface LogState {
	//sessionGuid: string;
	error: ErrorMessage;
	logsOperationState: DNOperationState;
	logCount: number;
}
