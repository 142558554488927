import { loadProvince, loadProvinceSuccess, loadProvinceFailure } from './province.actions';
import { createReducer, on } from '@ngrx/store';
import { provinceAdapter, provinceInitialState } from './province.entity';

export const province = createReducer(
	provinceInitialState,
	on(loadProvince, (state) => ({ ...state, error: undefined })),
	on(loadProvinceSuccess, (state, action) => provinceAdapter.setAll(action.datiProvince, state)),
	on(loadProvinceFailure, (state, action) => ({ ...state, error: action.error }))
);
