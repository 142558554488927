import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { Category } from './category.model';

export const loadCategory = createAction('[Category] LoadCategory');

export const loadCategorySuccess = createAction(
	'[Category] loadCategorySuccess',
	props<{ datiCategory: Category[] }>()
);
export const loadCategoryFailure = createAction(
	'[Category] loadCategoryFailure',
	props<{ error: ErrorMessage }>()
);
