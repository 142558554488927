import { createReducer, on } from '@ngrx/store';
import {
	clearCurrentTopic,
	loadCurrentTopicSuccess,
	loadTopic,
	loadTopicFailure,
	loadTopicSuccess
} from './topic.actions';
import {
	topicInitialState,
	topicListAdapter,
	topicListInitialState,
	topicAdapter
} from './topic.entity';

export const topicList = createReducer(
	topicListInitialState,
	on(loadTopic, (state) => ({ ...state, error: undefined })),
	on(loadTopicSuccess, (state, action) => topicListAdapter.setAll(action.datiTopic, state)),

	on(loadTopicFailure, (state, action) => ({ ...state, error: action.error }))
);

export const currentTopic = createReducer(
	topicInitialState,
	on(loadCurrentTopicSuccess, (state, action) => topicAdapter.upsertOne(action.topic, state)),
	on(clearCurrentTopic, (state) => ({ ...topicInitialState }))
);
