import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallService } from '../../core/call-service/call.service';
import { LogService } from '../../core/log/log.service';
import { getCitiesByPrefixUrl } from '../../core/router/routes-provider';
import { City } from './city.model';

@Injectable({ providedIn: 'root' })
export class CityService {
	constructor(private callService: CallService, private logService: LogService) {}

	loadCityByPrefix(prefix: string): Observable<City[]> {
		const urltoCall = getCitiesByPrefixUrl(prefix);
		this.logService.info('Calling ', urltoCall);
		const result = this.callService.CallApi<City[]>('GET', urltoCall);
		return result;
	}
}
