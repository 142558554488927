import { createSelector } from '@ngrx/store';
import { selectProvinceState } from '../../shared/shared.state';
import { ErrorMessage } from '../error/error-message.model';
import { provinceSelectors, ProvinceState } from './province.entity';

export const selectProvince = createSelector(selectProvinceState, provinceSelectors.selectAll);

export const selectCatError = createSelector(
	selectProvinceState,
	(state: ProvinceState): ErrorMessage => state.error
);
