import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';

import { SpinnerComponent } from './spinner.component';

@NgModule({
	imports: [SharedModule],
	exports: [SpinnerComponent],
	declarations: [SpinnerComponent],
	providers: []
})
export class SpinnerModule {}
