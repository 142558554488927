import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CITY_FEATURE_NAME } from '../../shared/shared.state';
import { CityEffects } from './city.effects';
import { city } from './city.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(CITY_FEATURE_NAME, city),
		EffectsModule.forFeature([CityEffects])
	],
	exports: [],
	declarations: [],
	providers: []
})
export class CityModule {}
