/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import * as FormData from 'form-data';
import { Observable, of } from 'rxjs';
import { EnvironmentService } from '../../../environments/environment.service';
import { CallService } from '../call-service/call.service';
import { HealthCheck, LoginType, ServerAuthData, Token } from './auth.models';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private loginUrl = this.environment.loginUrl;
	private softLoginUrl = this.environment.softLoginUrl;

	private renewTokenUrl = this.environment.renewTokenUrl;
	private logoutUrl = this.environment.logoutUrl;

	constructor(private environment: EnvironmentService, private callService: CallService) { }

	login(
		email: string,
		password: string,
		rememberMe: boolean,
		loginType: LoginType
	): Observable<ServerAuthData> {
		const formData = {
			email: email,
			password: password,
			rememberme: rememberMe,
			logintype: loginType,
		};

		//  this.logService.info('auth-service, calling login:',true,true,this.loginUrl);
		const result = this.callService.CallAuth<ServerAuthData>(
			'POST',
			this.loginUrl,
			undefined,
			formData
		);
		return result;
	}

	softLogin(softToken: string): Observable<ServerAuthData> {
		const formData: any = new FormData();


		const result = this.callService.CallAuth<ServerAuthData>(
			'POST',
			this.softLoginUrl,
			softToken,
			formData
		);
		return result;
	}

	renewToken(renewToken: Token): Observable<ServerAuthData> {
		const formData: any = new FormData();


		const result = this.callService.CallRenew<ServerAuthData>(
			'POST',
			this.renewTokenUrl,
			renewToken,
			formData
		);
		return result;
	}

	logout(accessToken: Token): Observable<ServerAuthData> {
		const result = this.callService.CallAuth<ServerAuthData>(
			'POST',
			this.logoutUrl,
			accessToken.token
		);
		return result;
	}
	checkOnline(): Observable<HealthCheck> {
		return this.callService.CallHealthCheck();
	}
}
