import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../../shared.module';

import { SigninFormComponent } from './signin-form.component';

@NgModule({
	imports: [
		SharedModule,
		MatCheckboxModule,
		MatIconModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule
	],
	exports: [SigninFormComponent],
	declarations: [SigninFormComponent],
	providers: []
})
export class SigninFormModule {}
