import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorMessage } from '../error/error-message.model';
import { Specialty } from './specialty.model';

export const specialtyAdapter: EntityAdapter<Specialty> = createEntityAdapter<Specialty>({
	selectId: (specialty) => specialty.idSpecializzazione
});
export interface SpecialtyState extends EntityState<Specialty> {
	error: ErrorMessage;
}
export const specialtyInitialState: SpecialtyState = specialtyAdapter.getInitialState({
	error: undefined
});
export const specialtySelectors = specialtyAdapter.getSelectors();
