import { CookieConsentState } from './cookie-consent/cookie-consent.model';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EnvironmentService } from '../../environments/environment.service';
import { LogState } from '../domain/log/log.model';
import { logReducer } from '../domain/log/log.reducer';
import { UserState } from '../domain/user/user.model';
import { userReducer } from '../domain/user/user.reducer';
import { WidgetState } from '../domain/widget/widget.model';
import { widgetReducer } from '../domain/widget/widget.reducer';
import { AuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { RoutesStateUrl } from './router/routes.state';
import { SessionState } from './session/session.models';
import { sessionReducer } from './session/session.reducers';
import { cookieConsentReducer } from './cookie-consent/cookie-consent.reducer';
import { settingsReducer } from '../domain/settings/settings.reducer';
import { NewsletterAbbonamentoState } from '../domain/settings/settings.model';

export const USER_FEATURE_NAME: string = 'user';
export const WIDGET_FEATURE_NAME: string = 'widgets';
export const TOPIC_FEATURE_NAME: string = 'topic';

const environment = new EnvironmentService();

/*
export const metaReducers: MetaReducer<AppState>[] = [
  ];
if (!environment.production) {
	if (!environment.test) {
		metaReducers.unshift(metaReducerFactory());
	}
}
*/
export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectSessionState = createFeatureSelector<SessionState>('session');

export const selectRouterState =
	createFeatureSelector<RouterReducerState<RoutesStateUrl>>('routes');

export const selectUserState = createFeatureSelector<UserState>(USER_FEATURE_NAME);

export const selectWidgetState = createFeatureSelector<WidgetState>(WIDGET_FEATURE_NAME);

export const selectLogState = createFeatureSelector<LogState>('logs');

export const selectCookieConsentState = createFeatureSelector<CookieConsentState>('cookieconsent');

export const selectNewsletterAbbonamentoState =
	createFeatureSelector<NewsletterAbbonamentoState>('newsletterabbonamento');

export interface AppState {
	auth: AuthState;
	router: RouterReducerState<RoutesStateUrl>;
	session: SessionState;
	user: UserState;
	logs: LogState;
	widgets: WidgetState;
	cookieconsent: CookieConsentState;
	newsletterabbonamento: NewsletterAbbonamentoState;
}

export const reducers: ActionReducerMap<AppState> = {
	auth: authReducer,
	router: routerReducer,
	session: sessionReducer,
	user: userReducer,
	logs: logReducer,
	widgets: widgetReducer,
	cookieconsent: cookieConsentReducer,
	newsletterabbonamento: settingsReducer
};
