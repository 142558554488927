import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { VideoViewerModule } from '../../video-viewer/video-viewer.module';
import { DnDialogModule } from '../dn-dialog.module';

import { VideoPlayerDialogComponent } from './video-player-dialog-component';

@NgModule({
	imports: [DnDialogModule, SharedModule, VideoViewerModule],
	exports: [VideoPlayerDialogComponent],
	declarations: [VideoPlayerDialogComponent],
	providers: []
})
export class VideoPlayerDialogModule {}
