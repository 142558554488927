import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../shared.module';
import { DnDialogModule } from '../dn-dialog.module';
import { ChangePasswordDialogComponent } from './change-password-dialog.component';

@NgModule({
	imports: [
		SharedModule,
		DnDialogModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule
	],
	exports: [ChangePasswordDialogComponent],
	declarations: [ChangePasswordDialogComponent],
	providers: []
})
export class ChangePasswordDialogModule {}
