import { SafeHtml } from '@angular/platform-browser';
import { EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Content } from '../../../../domain/content/content.model';
import {
	isPageElement,
	PageElement
} from '../../../../domain/dynamic-container/dynamic-container.model';
import { UserDTO } from '../../../../domain/user/user.model';
import {
	CouponState,
	MyPremiumModuleElement,
	MyPremiumState,
	PremiumDiscountType,
	PremiumModuleElement,
	PremiumSubscription,
	PremiumTokenError
} from '../../../../domain/extra/extra.model';
import { replaceAll } from '../../../../shared/util/util';

const STARTING_DELIMITER: string = '{{';
const CLOSING_DELIMITER: string = '}}';

const COLLECTION_INJECTION_LABEL: string = 'dati';

export enum NewWidgetsTemplateId {
	NEW_COLLECTION = 174,
	NEW_CAROUSEL = 175,
	MENU = 164,
	NEW_IFRAME = 165,
	FOLLOW = 166,
	NEW_HTML = 167,
	ZOOM = 168,
	PAYMENT_FORM = 171,
	REP_BLOG = 161
}

export const isNewWidgetValid = (templateId: number) =>
	Object.values(NewWidgetsTemplateId).includes(templateId);

export interface ParametersToken {
	id_anagrafica?: number;
}

export interface resizeEmitterStruct {
	minHeight: number;
	owner: string;
}

export interface PaymentFormWidget {
	premiumModules$: Observable<PremiumModuleElement[]>;
	discountTypeList$: Observable<PremiumDiscountType[]>;
	tokenError$: Observable<PremiumTokenError>;
	coupon$: Observable<CouponState>;
	myPremium$: Observable<MyPremiumState>;
	submitUpdateSubscription?: EventEmitter<MyPremiumModuleElement>;
	submitCheckTokenForm?: EventEmitter<string>;
	submitSubscription?: EventEmitter<PremiumSubscription>;
	submitLoadUserPremium?: EventEmitter<number>;
	submitCouponGeneration?: EventEmitter<{
		idTipoSconto: number;
		idAnagrafica: number;
	}>;
}

export interface CollectionWidget {
	collectionContainerHtml: string;
}

export interface FollowWidget {
	isFollowing: boolean;
	followChannelEmitter?: EventEmitter<boolean>;
}

export interface ListWidget {
	contentList: Content[];
}

export interface GenericHtmlWidget {
	outerHtml: SafeHtml;
}

export interface ZoomWidget {
	sdkKey: string;
	meetingNumber: string;
}

export interface Rep {
	nome: string;
	cognome: string;
	foto: string;
	stanza: string;
	mail: string;
}

export interface RepBlogWidget {
	rep$: Observable<Rep>;
}

export interface IframeWidget {
	iFrameResize$: Subject<resizeEmitterStruct>;
	saveClickEmitter?: EventEmitter<string>;
	resizeEmitter?: EventEmitter<resizeEmitterStruct>;
}

export interface NewWidgetData {
	currentElement: PageElement;
	idContainer?: number;
	parametersToken?: ParametersToken;
	user?: UserDTO;
	owner: string;
	outerClass?: string;
	widgetData?:
		| PaymentFormWidget
		| CollectionWidget
		| IframeWidget
		| FollowWidget
		| ListWidget
		| GenericHtmlWidget
		| ZoomWidget
		| RepBlogWidget
		| {};
}

export interface NewWidget {
	data: NewWidgetData;
}

export function readPropertyInsideDelimiters(
	startingToken: string,
	closingToken: string,
	substitutionVariable: string
): string {
	return substitutionVariable.replace(startingToken, '').replace(closingToken, '');
}

export function substituteVariables(
	text: string,
	currentElement: PageElement | Content | ParametersToken
): string {
	if (!text) {
		return 'You passed an empty template';
	}

	let result: string = text;

	const regexMatcher: RegExp = new RegExp(
		'\\' + STARTING_DELIMITER + '(.+?)' + CLOSING_DELIMITER,
		'g'
	);

	const variablesMatching: string[] = text.match(regexMatcher);

	if (variablesMatching?.length > 0) {
		for (const substitutionVariable of variablesMatching) {
			const propertyName: string = readPropertyInsideDelimiters(
				STARTING_DELIMITER,
				CLOSING_DELIMITER,
				substitutionVariable
			);

			if (isPageElement(currentElement) && propertyName === COLLECTION_INJECTION_LABEL) {
				let collectionElementsHtml: string = '';

				for (const collectionElement of currentElement.contentList) {
					collectionElementsHtml += substituteVariables(
						collectionElement.htmlDetail,
						collectionElement
					);
				}

				result = replaceAll(result, substitutionVariable, collectionElementsHtml);
			} else {
				// If the field is a date, first format it
				if (propertyName === 'dataCreazione') {
					result = replaceAll(
						result,
						substitutionVariable,
						new Date(currentElement[propertyName]).toLocaleDateString()
					);
				} else {
					result = replaceAll(result, substitutionVariable, currentElement[propertyName]);
				}
			}
		}
	}

	return result;
}
