import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

const APP_PREFIX = 'DOTTNET-';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

	setItem(key: string, value: any) {
		if (isPlatformBrowser(this.platformId))
			localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
	}

	getItem(key: string) {
		if (
			isPlatformBrowser(this.platformId) &&
			localStorage.getItem(`${APP_PREFIX}${key}`) !== 'undefined'
		)
			return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
	}

	removeItem(key: string) {
		if (isPlatformBrowser(this.platformId)) localStorage.removeItem(`${APP_PREFIX}${key}`);
	}
}
