import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { UserFormComponent } from './user-form.component';
import { UserFormEffects } from './user-form.effects';

@NgModule({
	imports: [
		SharedModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatInputModule,
		MatButtonModule,
		MatFormFieldModule,
		MatDatepickerModule,
		ReactiveFormsModule,
		SpinnerModule,

		EffectsModule.forFeature([UserFormEffects])
	],
	exports: [UserFormComponent],
	declarations: [UserFormComponent],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
	]
})
export class UserFormModule {}
