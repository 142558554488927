import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CookieConsentComponent } from './cookie-consent.component';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';
import { CookieService } from './services/cookie/cookie.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IfConsentDirective } from './directives/if-consent/if-consent.directive';

@NgModule({
	declarations: [CookieConsentComponent, IfConsentDirective],
	imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TranslateModule],
	exports: [CookieConsentComponent, IfConsentDirective]
})
export class CookieConsentModule {
	static forRoot(config = {}): ModuleWithProviders<CookieConsentModule> {
		return {
			ngModule: CookieConsentModule,
			providers: [CookieConsentService, CookieService]
		};
	}
}
