import { createReducer, on } from '@ngrx/store';
import { loadSpecialty, loadSpecialtyFailure, loadSpecialtySuccess } from './specialty.actions';
import { specialtyAdapter, specialtyInitialState } from './specialty.entity';

export const specialty = createReducer(
	specialtyInitialState,
	on(loadSpecialty, (state) => ({ ...state, error: undefined })),
	on(loadSpecialtySuccess, (state, action) => specialtyAdapter.setAll(action.datiSpecialty, state)),
	on(loadSpecialtyFailure, (state, action) => ({ ...state, error: action.error }))
);
