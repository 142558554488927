import { MatSnackBarConfig } from '@angular/material/snack-bar';

export enum SeverityDN {
	SUCCESS,
	WARNING,
	ERROR
}

export enum SnackbarStyles {
	SUCCESS = 'success-notification-overlay',
	ERROR = 'error-notification-overlay',
	INFO = 'info-notification-overlay'
}

export class NotificationDN {
	readonly severity: SeverityDN;
	readonly code: number;
	readonly message: string;
	readonly action: string;
	readonly config: MatSnackBarConfig;

	constructor(
		severity: SeverityDN,
		code: number,
		message: string,
		action: string = 'OK',
		durationSeconds: number = 5,
		snackbarStyle: SnackbarStyles
	) {
		this.severity = severity;
		this.code = code;
		this.message = message;
		this.action = action;
		this.config = {
			duration: durationSeconds * 1000,
			// Non utilizzo toString() perché
			// le enum in ts sono automaticamente trasformate nel valore corrispondente
			panelClass: snackbarStyle
		};
	}
}
